import { useEffect, useState } from "react";
import "../../assets/css/listGift.css";
import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import GiftItem from "./GiftItem";
import { useSelector } from "react-redux";
import { setAuthorization } from "../../services/apiService/configURL";
import { userServices } from "../../services/apiService/userServices";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/fontawesome/image/logo_mvc.png";

let buttonBack = "/";
const TITLE = "Quà của tôi";
export default function () {
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });
  const location = useLocation();
  const back = location.pathname.split("/")[2];
  const [current, setCurrent] = useState("1");

  const [items, setItems] = useState();
  const [statusGifts, setStatusGifts] = useState("done");
  const hanldeClick = (e, status_gifts) => {
    setCurrent(e.target.id);
    userServices
      .getMyPrizeList(status_gifts.toString())
      .then((res) => {
        console.log(res);
        setItems(res.list_prizes);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  setAuthorization(token);
  useEffect(() => {
    window.scrollTo(0, 0);
    userServices
      .getMyPrizeList(statusGifts.toString())
      .then((res) => {
        console.log(res);
        setItems(res.list_prizes);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }, []);
  return (
    <div>
      <HeaderBackground
        TITLE={TITLE}
        buttonBack={`${back === "tick" ? "/infor-customer" : buttonBack}`}
      />
      <div className="containerNotify__background absolute bottom-0 h-[86%] w-full z-10">
        <div className=" mx-[25px] bg-[#F0F0F0] h-[52px] mt-[28px] mb-3 rounded-2xl pb-4">
          <div className="tab flex justify-between items-center align-middle">
            <div className="flex justify-center flex-auto">
              <button
                key="1"
                id={1}
                disabled={current === `${1}`}
                onClick={(e) => hanldeClick(e, "done")}
                className="font-semibold mt-[6px] text-[13px]"
              >
                Voucher
              </button>
            </div>
            <div className="flex justify-center flex-auto">
              <button
                key="2"
                id={2}
                disabled={current === `${2}`}
                onClick={(e) => hanldeClick(e, "not_yet")}
                className="font-semibold mt-[6px] text-[13px]"
              >
                Vật lý
              </button>
            </div>
          </div>
        </div>
        <ul className="containerNotify__background-listnoti pt-7 box-border bottom-20 z-30 ">
          {items?.map((infor, index) => {
            return (
              <li
                className="mx-[25px] bg-[#f0f0f0] rounded-xl mb-4 overflow-hidden"
                key={index}
              >
                <GiftItem infor={infor} current={current} />
              </li>
            );
          })}
        </ul>
        <div className="h-40"></div>
      </div>
      {/* <div className="h-40"></div> */}

      <IconPhoneAndZalo />
      <NavbarHome />
    </div>
  );
}

import Modal from "react-modal";
import check_green from "../../assets/fontawesome/image/check-green.png";
import GiftItem from "./GiftItem";
import phone_icon from "../../assets/fontawesome/image/phone-icon.png";
import play_icon from "../../assets/fontawesome/image/play-icon.png";
import { useForm } from "react-hook-form";
import { userServices } from "../../services/apiService/userServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import headerPopup from "../../assets/fontawesome/image/header_popup.png";
import { userDataLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import { useSelector } from "react-redux";
import { stringify } from "postcss";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    borderRadius: "32px",
  },
};
export default function ModalGift({
  modalIsOpen,
  soIds,
  listPrize,
  status_lucky,
}) {
  console.log(status_lucky);
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });

  const navigate = useNavigate();
  const hotline = localStorage.getItem("HOTLINE");

  const handleOnSubmit = () => {
    let info = { so_ids: soIds };
    console.log(info);
    console.log(soIds);
    userServices
      .postUpdateConsultant(info)
      .then((res) => {
        if (status_lucky === true) {
          console.log(res);
          console.log(res.so_ids.length);

          if (res.so_ids.length !== 1) {
            navigate("/list-rotation");
          } else {
            navigate(`/wheel/${soIds}`);
          }
        } else {
          navigate("/list-gift");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Đã xảy ra lỗi. Vui lòng thử lại sau");
      });
  };
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Gift Modal"
        ariaHideApp={false}
      >
        {/* <form
          className="w-[370px] rounded-[32px]"
        onSubmit={handleSubmit(onSubmit)}
        > */}
        <div className="w-[370px] rounded-[32px]">
          <div className="guide-take-photo modal-header relative flex-col items-center justify-center rounded-[32px]">
            <img src={headerPopup} />
            <div className="modal-body w-full rounded-[32px] bg-[#FFFFFF] ">
              <div className="check">
                <img className="" src={check_green} alt="" />
              </div>
              <div className="-mt-5 flex w-full flex-col items-center justify-center px-5">
                <div className="text-center ">
                  <h1 className="text-[24px] uppercase text-[#003DA5]">
                    thông báo
                  </h1>
                  <p className="text-[12px] font-light">
                    Hóa đơn của bạn hợp lệ <br />
                    bạn nhận được các phần thưởng sau
                  </p>
                </div>
                <div className="mx-4 my-4 max-h-[360px] w-full overflow-scroll">
                  <ul className="containerNotify__background-list z-30  box-border flex flex-col items-center justify-center ">
                    {listPrize.map((infor, index) => {
                      return (
                        <li
                          key={index}
                          className="mb-4 w-[290px] overflow-hidden rounded-xl bg-[#f0f0f0] px-[10] pt-[6px]  "
                        >
                          <GiftItem infor={infor} />
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="mt-[25px]  flex gap-1">
                  <a
                    href={"tel:" + hotline}
                    className="hotline-btn mt-1 mr-3 flex
                         h-14 w-32 items-center justify-center gap-1 rounded-[16px] bg-[#96C61C] px-[1px] py-[3px] text-[#130682]"
                  >
                    <img src={phone_icon} alt="" className="" />
                    <span className="text-[13px] font-semibold text-white">
                      Gọi Hỗ trợ
                      <br />
                      {hotline}
                    </span>
                  </a>
                  <button
                    className="btn-zoom btn-color-ani hotline-btn flex h-16 w-[148px]
                        items-center justify-center gap-1 rounded-[16px] bg-[#003DA5] px-[1px] py-[12px] text-[#fff]"
                    onClick={handleOnSubmit}
                  >
                    <img src={play_icon} alt="" className="pl-1" />
                    <span className="text-[12px] font-semibold">
                      Bấm để nhận quà hoặc vòng quay
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </Modal>
    </div>
  );
}

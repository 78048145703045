import { useState } from "react";
import "../../assets/css/font-text.css";
import { useEffect } from "react";

export default function GiftItem({ infor, current }) {
  console.log(infor);
  const [status, setStatus] = useState(false);
  const handleToggle = () => {
    setStatus(!status);
  };
  useEffect(() => {
    setStatus(false);
  }, [current]);
  function reformatDate(dateStr) {
    const date = dateStr.substring(0, dateStr.indexOf(" "));
    var dArr = date.split("-"); // ex input: "2010-01-18"
    // console.log(dArr);
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex output: "18/01/10"
  }
  function convertDateFormat(dateString) {
    var parts = dateString.split("-");
    return parts[2] + "/" + parts[1] + "/" + parts[0];
  }
  return (
    <>
      <div
        className="relative z-50 flex pt-[13px] pl-[14px]"
        // onClick={handleToggle}
      >
        <div className="flex items-center">
          <img src={infor.gift_image} className="w-16 " />
        </div>
        <div className="ml-2">
          <div className="text-reponsive font-regular text-[16px] leading-6">
            Bạn đã trúng phần quà là
          </div>
          <div className="text-item-detailgift text-[16px] ">{infor.gift}</div>
          {infor.exchange_status_str === "Đã nhận" ? (
            <div className="item-status text-red-600">
              {infor.exchange_status_str}
            </div>
          ) : (
            <div className="item-status text-[#00AF43]">
              {infor.exchange_status_str}
            </div>
          )}
        </div>
      </div>
      {status === true ? (
        <div
          className="product-infomation pl-[14px]"
          //  onClick={handleToggle}
        >
          <div className="text-item-gift">
            ID
            <div className="text-item-detailgift pr-3 text-[#363534]">
              {infor.id}
            </div>
          </div>
          <div className="text-item-gift">
            Chương trình
            <div className="text-item-detailgift pr-3 text-right text-[#52A0F7]">
              {infor.campaign_name}
            </div>
          </div>
          <div className="text-item-gift">
            Ngày tham gia
            <div className="text-item-detailgift corlor-text-darkblack pr-3">
              {infor.attend_date}
            </div>
          </div>
          {infor?.gift_type !== "urbox" ? (
            <div className="text-item-gift">
              Ngày đổi quà
              <div className="text-item-detailgift corlor-text-darkblack pr-3">
                {reformatDate(infor.exchange_date)}
              </div>
            </div>
          ) : null}
          {infor?.gift_type === "urbox" ? (
            <>
              {!infor?.is_topup_gift ? (
                <div className="text-item-gift">
                  Ngày hết hạn sử dụng
                  <div className="text-item-detailgift corlor-text-darkblue pr-3">
                    {convertDateFormat(infor?.urbox_expired)}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          {infor?.gift_type === "urbox" ? (
            <>
              {!infor?.is_topup_gift ? (
                <div className="text-item-gift">
                  UrBox Link
                  <div className="text-item-detailgift corlor-text-darkblue pr-3">
                    <a href={infor?.urbox_link} target="_blank">
                      {infor?.urbox_link}
                    </a>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          {infor?.gift_type === "urbox" ? (
            <>
              {!infor?.is_topup_gift ? (
                <div className="text-item-gift">
                  Mã bảo vệ UrBox
                  <div className="text-item-detailgift corlor-text-darkblue pr-3">
                    {infor?.urbox_pin}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          <div className="text-item-gift">
            <div className="corlor-text-darkblue pr-3 font-normal"></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="flex justify-between " onClick={handleToggle}>
        {status === true ? (
          <>
            {infor.is_physical_gift ? (
              <div className="img-code ml-2 mt-2 flex items-center justify-center bg-white ">
                <img
                  src={"data:image/png;base64, " + infor?.qrcode}
                  className="w-52"
                />
              </div>
            ) : (
              <div className="ml-2 mt-2 flex items-center justify-center bg-white "></div>
            )}
          </>
        ) : (
          <div className="font-text-13 font-regular mt-2 pl-5 text-[#4F4F4F]">
            {current === "1" ? "Ngày nhận quà" : "Ngày tham gia"}{" "}
            <span className="text-[#003DA5]">
              {current === "1"
                ? infor.received_gift_date?.split(" ")[0]
                : infor.attend_date}
            </span>
          </div>
        )}
        <div
          className=" flex flex-col items-end justify-end"
          onClick={handleToggle}
        >
          <div className=" bgr-icon-back background-bottom-icon z-30 flex h-10 w-10">
            {status === true ? (
              <div className="icon-droptop icon-size"></div>
            ) : (
              <div className="icon-dropdown icon-size"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function ItemList({ res }) {
  return (
    <>
      <div className="col-span-4 font-semibold">{res?.gift_name}</div>
      <div
        className="col-span-2 text-right"
        style={
          res?.status_value === "Đã đổi"
            ? { color: "red" }
            : { color: "#014FA2" }
        }
      >
        {res?.status_value}
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import "./ListGiftForCustomer.css";
import TabGift from "./TabGift";
import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import { campaignServices } from "../../services/apiService/campaignServices";
import { useParams } from "react-router-dom";
import TabReward from "./TabReward";
import { Pagination } from "antd";
const TITLE = "Danh sách quà tặng";
const data = {
  total_of_page: 0,
  maximum_of_page: 10,
  current_page: 1,
  total_gift: 0,
  total_gift_curr_page: 0,
  list_prizes: [
    {
      name: "Evoucher Thailand",
      phone: 1200,
      time: 200,
    },
    {
      name: "Evoucher VietNam",
      phone: 1200,
      time: 200,
    },
    {
      name: "Evoucher Korean",
      phone: 1200,
      time: 200,
    },
    {
      name: "Evoucher Japan",
      phone: 1200,
      time: 200,
    },
    {
      name: "Evoucher Thailand",
      phone: 1200,
      time: 200,
    },
    {
      name: "Evoucher China",
      phone: 1200,
      time: 200,
    },
  ],
};
export default function ListGiftForCustomer() {
  const [current, setCurrent] = useState("1");
  const { id } = useParams();
  const [data_gift, set_data_gift] = useState();
  const [data_prize, set_data_prize] = useState();
  const [totalPrize, setTotalPrize] = useState();
  console.log(id);
  const hanldeClick = (e, status_gifts) => {
    setCurrent(e.target.id);
    getMyListGift(status_gifts);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    campaignServices
      .getCampaignGift(id)
      .then((res) => {
        set_data_gift(res.list_gift);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    campaignServices
      .getCampaignPrize(id, 10, 1)
      .then((res) => {
        set_data_prize(res.list_prizes);
        console.log(res);
        setTotalPrize(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(data_prize);
  const getMyListGift = (val) => {
    // userServices
    //   .getMyPrizeList(statusGifts)
    //   .then((res) => {
    //     console.log(res);
    //     const resp = res.list_reward.filter(
    //       (x) => x.validated === val && x.campaign_id !== 1
    //     );
    //     console.log(resp);
    //     setItems(resp);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast.error(err);
    //   });
  };

  const handleD = (data) => {
    console.log(data);
    campaignServices
      .getCampaignPrize(id, 10, data)
      .then((res) => {
        set_data_prize(res.list_prizes);
        console.log(res);
        setTotalPrize(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <HeaderBackground TITLE={TITLE} buttonBack={`/`} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[86%] w-full z-10">
        <div className="containerNotify__background-list font-light box-border z-20">
          <div className="tab1 flex justify-between items-center align-middle mt-5">
            <div className="flex justify-center flex-auto">
              <button
                key="1"
                id={1}
                disabled={current === `${1}`}
                onClick={(e) => hanldeClick(e, true)}
                className="font-semibold mt-[6px]"
              >
                Danh sách quà tặng
              </button>
            </div>
            <div className="flex justify-center flex-auto">
              <button
                key="2"
                id={2}
                disabled={current === `${2}`}
                onClick={(e) => hanldeClick(e, false)}
                className="font-semibold mt-[6px]"
              >
                Danh sách trúng thưởng
              </button>
            </div>
          </div>
          <div className=" box-border  mx-4 mt-5">
            {current === "1" ? (
              <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                <thead className=" h-8 rounded-t-md">
                  <tr className=" text-white font-light rounded-t-2xl">
                    <th className="border-head rounded-tl-xl pl-5 text-left">
                      Tên quà
                    </th>
                    <th className="border-head">Tổng quà</th>
                    <th className="border-head">Đã ra</th>
                    <th className="border-head rounded-tr-xl">Còn lại</th>
                  </tr>
                </thead>
                <tbody className="font-bold">
                  {data_gift?.map((item, index) => {
                    if (item?.gift === "Chúc bạn may mắn") {
                      return <></>;
                    } else {
                      return <TabGift item={item} key={index} />;
                    }
                  })}
                </tbody>
              </table>
            ) : (
              <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                <thead className=" h-8 rounded-t-md w-full">
                  <tr className="grid grid-cols-9 w-full h-8 text-white font-light rounded-t-2xl">
                    <th className="border-head flex items-center rounded-tl-xl pl-5 text-left col-span-2">
                      Sđt
                    </th>
                    <th className="border-head flex items-center text-left col-span-5">
                      Giải thưởng
                    </th>
                    <th className="border-head flex items-center rounded-tr-xl col-span-2">
                      Thời gian
                    </th>
                  </tr>
                </thead>
                <tbody className="font-bold">
                  {data_prize?.map((item, index) => (
                    <TabReward item={item} key={index} />
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {current === "2" ? (
            <div className="flex justify-center mt-2">
              <Pagination
                defaultCurrent={1}
                total={totalPrize?.total_gift}
                onChange={handleD}
              />
            </div>
          ) : (
            ""
          )}
          <div className="h-40"></div>
        </div>

        <div className="h-40"></div>
        <IconPhoneAndZalo />
      </div>
      <div className="h-40"></div>
      <FooterLogo />
    </div>
  );
}

import React from "react";
import { NavLink } from "react-router-dom";

export default function CarouselMiddleItem({ item }) {
  const handleBannerID = () => {
    console.log(item.id);
  };
  return (
    <NavLink to={item.id === 3 ? `` : `/deal-details/${item.id}`}>
      <div className=" rounded-2xl" onClick={handleBannerID}>
        <img
          src={item.home_banner}
          className="px-0 max-h-[151px] rounded-2xl "
        />
      </div>
    </NavLink>
  );
}

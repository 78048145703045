import { Carousel } from "react-responsive-carousel";
import ICON_DOTS from "../../assets/fontawesome/image/icon-dots.svg";
import ICON_DOTS_PRIMARY from "../../assets/fontawesome/image/icon-dots-primary.svg";
import BTN_NEXT_GUIDE from "../../assets/fontawesome/image/btn-cancel-guide.svg";
import B1_TAKEAPHOTO from "../../assets/fontawesome/image/m1.png";
import B2_TAKEAPHOTO from "../../assets/fontawesome/image/m2.png";
import B3_TAKEAPHOTO from "../../assets/fontawesome/image/m3.png";
import B4_TAKEAPHOTO from "../../assets/fontawesome/image/b4-takeaphoto.svg";
import B5_TAKEAPHOTO from "../../assets/fontawesome/image/b5-takeaphoto.svg";
import CarouselTakeAPhoto from "./CarouselTakeAPhoto";
import GiftIconImg from "../..//assets/fontawesome/image/gift.png";
import P from "../../assets/fontawesome/image/p.png";
import ICON_EXPLAIN from "../../assets/fontawesome/image/icon-explain.svg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import { format } from "date-fns";
import uuid from "react-uuid";
import { campaignServices } from "../../services/apiService/campaignServices";
import { homeServices } from "../../services/apiService/homeServices";
import { ocrServices } from "../../services/apiService/ocrServices";
import { receiptServices } from "../../services/apiService/receiptServices";
import "./GuideTakePhoto.css";
import { toast } from "react-toastify";
import { userDataLocal } from "../../services/localService/localService";
import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import ModalGift from "./ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import { Camera } from "../../component/Camera";
import CAM from "../../assets/fontawesome/image/cam.png";
import CountDownOrder from "./CountDownOrder";

export default function GuideTakeAPhoto() {
  const nameStaff = localStorage.getItem("nameStaff");
  const hotline = localStorage.getItem("HOTLINE");

  const [imageFile, setimageFile] = useState(undefined);
  const [isShowModalErr, setIsShowModalErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  let [ocrEndpoint, setOcrEndpoint] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [listPrize, setListPrize] = useState([]);
  const [so_ids, setSo_ids] = useState([]);
  const [isAskLogin, setIsAskLogin] = useState(false);
  const navigate = useNavigate();
  let { token } = userDataLocal.get();

  let { campaignId } = useParams();
  console.log(campaignId);
  let refInputUpload = useRef(null);

  const onClickUpload = (event) => {
    refInputUpload.current?.click();
  };

  const handlePopupErrorOk = () => {
    console.log("popup press ok");
    navigate("/");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const handleChangeImage = (event) => {
  //   console.log(event);
  //   let fileUploaded = event;
  //   const fileUploadedSize = fileUploaded.size / 1024 / 1024;
  //   if (fileUploadedSize > 20) {
  //     new Compressor(fileUploaded, {
  //       quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
  //       success: (res) => {
  //         setimageFile(res);
  //       },
  //     });
  //   } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
  //     new Compressor(fileUploaded, {
  //       quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
  //       success: (res) => {
  //         setimageFile(res);
  //       },
  //     });
  //   } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
  //     new Compressor(fileUploaded, {
  //       quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
  //       success: (res) => {
  //         setimageFile(res);
  //       },
  //     });
  //   } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
  //     new Compressor(fileUploaded, {
  //       quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //       success: (res) => {
  //         setimageFile(res);
  //       },
  //     });
  //   } else {
  //     setimageFile(fileUploaded);
  //   }
  // };
  const handleChangeImage = (event) => {
    console.log(event);
    let fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    const fileUploadedSize = fileUploaded.size / 1024 / 1024;
    if (fileUploadedSize > 20) {
      new Compressor(fileUploaded, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
      new Compressor(fileUploaded, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
      new Compressor(fileUploaded, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
      new Compressor(fileUploaded, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else {
      setimageFile(fileUploaded);
    }
    console.log(fileUploaded);
  };
  const images = [
    {
      id: 1,
      url: B1_TAKEAPHOTO,
      title: `
      <ul id="a">
      <li>Chụp hóa đơn GỐC và chụp TOÀN BỘ hóa đơn
      </li>
      <li>Thấy rõ Tên siêu thị, số hóa đơn, sản phẩm, ngày mua.
      </li>
      </ul>`,
    },
    {
      id: 2,
      url: B2_TAKEAPHOTO,
      title: `
      <ul id="a">
      <li>Chụp hóa đơn RÕ RÀNG trong điều kiện đủ ánh sáng.
      </li>
      <li>Nền phía sau trơn - đơn giản
      </li>
      <li>Chú ý không dùng tay che hóa đơn</li>
      </ul>`,
    },
    {
      id: 3,
      url: B3_TAKEAPHOTO,
      title: `
      <ul id="a">
      <li>Gấp hóa đơn lại để hiển thị rõ phần tên sản phẩm và số lượng
      </li>
      <li>Không gấp hoặc che các thông tin ở phần đầu và cuối hóa đơn
      </li>
      </ul>`,
    },
    // {
    //   id: 4,
    //   url: B4_TAKEAPHOTO,
    //   title: "Không thấy rõ tên, sản phẩm, ngày, số hóa đơn",
    // },
    // {
    //   id: 5,
    //   url: B5_TAKEAPHOTO,
    //   title: "Hóa đơn đã bị chỉnh sửa (photoshop)",
    // },
  ];

  const [inputName, setInputName] = useState("");

  const getCampaignDetail = (campaignId) => {
    campaignServices
      .getCampaignDetailApi(campaignId)
      .then((res) => {
        console.log(res);
        setOcrEndpoint(res.ocr_endpoint);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getHomepageBanner = () => {
    homeServices
      .getHomepageBannerApi()
      .then((res) => {
        console.log(res);
        setOcrEndpoint(res.ocr_endpoint);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOcrEndPoint = (campaignId) => {
    if (campaignId) {
      getCampaignDetail(campaignId);
    } else {
      getHomepageBanner();
    }
  };

  useEffect(() => {
    getOcrEndPoint(campaignId);
  }, [campaignId]);
  const pushImageToGCS = () => {
    localStorage.setItem("nameStaff", inputName);
    console.log("hello");
    let formDataGCS = new FormData();
    console.log("minh");
    formDataGCS.append("file", imageFile);
    const fileName =
      uuid() +
      "_" +
      format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
      "_" +
      imageFile.name;
    formDataGCS.append("fileName", fileName);
    formDataGCS.append("ocrBase", ocrEndpoint);
    if (!token) {
      console.log("la");
      setIsAskLogin(true);
    } else {
      console.log("tai");
      setIsUpload(true);
    }
    ocrServices
      .uploadImageToOCR(formDataGCS)
      .then((res) => {
        console.log(res);
        if (campaignId) {
          console.log("campaignId", campaignId);
          res.data.campaign_id = campaignId;
        }
        localStorage.setItem("GCS_RESULT", JSON.stringify(res.data));
      })
      .then((res) => {
        if (token) {
          console.log("campaignId", campaignId);

          let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
          submitReceipt(gcsResult);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUpload(false);
      });
  };
  const onClickDeleteImg = (e) => {
    setimageFile(undefined);
    setImage(undefined);
    setOpenCam(true);
    // refInputUpload.current?.click();
  };
  const [status_lucky, set_status_lucky] = useState(false);
  const submitReceipt = (gcsResult) => {
    console.log(gcsResult);
    let formData = new FormData();
    console.log("tai ne");
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    //   formData.append("gsutil_url", `gs://mvcpro_vn/f44467dc-1b05-c528-ba66-831cce9eadff_26-05-2024-16-43-53_image.jpg`);
    //   formData.append("public_url", `https://storage.googleapis.com/mvcpro_vn/f44467dc-1b05-c528-ba66-831cce9eadff_26-05-2024-16-43-53_image.jpg`);
    //   formData.append("ocr_result", `{
    //     "customer_name": "WM HAI Nguyen Van co 131 Nguyen Van Cu",
    //     "counter": "3",
    //     "cashier": "19632969-NGAN",
    //     "date_time": "27/05/2024 10:00:00",
    //     "order_number": "99997",
    //     "number_items": "",
    //     "runtime": "3.04",
    //     "product_list": [
    //         {
    //             "barcode": "8934712701585",
    //             "description": "TP NM CA HOI TOI DEN 490MLX12",
    //             "quantity": 1,
    //             "unitPrice": "39000",
    //             "lineTotalNet": "153008"
    //         }
    //     ],
    //     "total_discount": "92180",
    //     "total_gross": "891188",
    //     "promotion": true,
    //     "received_creceipt_datetime": "2024-05-27 10:59:42",
    //     "response_result_datetime": "2024-05-27 10:59:42",
    //     "chain": "vinmart"
    // }`);
    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss")
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );
    if (gcsResult.campaign_id) {
      formData.append("campaign_id", gcsResult.campaign_id);
    }
    receiptServices
      .submitReceiptApi(formData)
      .then((res) => {
        set_status_lucky(res?.lucky_draw);
        console.log("result ", res);
        console.log("soid ", res.so_ids);
        setListPrize(res.prize_list);
        setSo_ids(res.so_ids);
        toast.success(res);
        setIsUpload(false);
        setIsOpen(true);
      })
      .catch((err) => {
        console.log("err ", err);
        setErrMsg(err);
        setIsShowModalErr(true);
        setIsUpload(false);
      })
      .finally(() => {
        localStorage.removeItem("GCS_RESULT");
      });
  };

  const [image, setImage] = useState(undefined);
  const [openCam, setOpenCam] = useState(false);
  // useEffect(() => {
  //   (async () => {
  //     const devices = await navigator.mediaDevices.enumerateDevices();
  //     const videoDevices = devices.filter((i) => i.kind == "videoinput");
  //     console.log(videoDevices);
  //     // setDevices(videoDevices);
  //     const font = ["Webcam", "back", "Camera mặt sau", "Back", "cực rộng"];
  //     const matching = videoDevices.filter((l) => {
  //       return font.some((term) => l.label.includes(term));
  //     });
  //     setDevices(matching);
  //   })();
  // });

  const [openPopup, setopenPopup] = useState(false);
  const openCamera = () => {
    setOpenCam(true);
    // setopenPopup(true);
    // setopenPopup(false)
  };

  const handlePopupOK = () => {
    setOpenCam(true);
  };
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  useEffect(() => {
    console.log(image);
    if (image !== undefined) {
      urltoFile(image, uuid() + uuid() + ".jpg", "image/jpeg").then(
        function (file) {
          console.log(file);
          handleChangeImage(file);
        }
      );
    }
  }, [image]);
  const camera = useRef(null);

  const handleCancelCam = () => {
    setimageFile(undefined);
    setImage(undefined);
    setOpenCam(false);
    setIsAskLogin(false);
  };

  const handleInputName = (e) => {
    setInputName(e.target.value);
  };
  return (
    <>
      {isUpload ? (
        <div className="full-height absolute  z-[200] flex w-full flex-col items-center justify-center backdrop-blur-sm">
          <div className="">
            <button className="load h-[100px] w-[100px]"></button>
          </div>
          <div className="flex justify-center px-[30px] text-center ">
            <p className="rounded-xl bg-slate-100 p-[10px] font-light ">
              Hóa đơn của quý khách đang được hệ thống ghi nhận. Vui lòng không
              rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* {openCam ? (
        <div className="popup-box-cam" style={{}}>
          {image === undefined ? (
            <>
              <Camera
                ref={camera}
                aspectRatio={9 / 13.6}
                videoSourceDeviceId={activeDeviceId}
                facingMode="environment"
                errorMessages={{
                  noCameraAccessible: "No camera device accessible",
                  permissionDenied:
                    "Permission denied. Please refresh and give camera permission.",
                  switchCamera:
                    "It is not possible to switch camera to different one because there is only one video device accessible.",
                  canvas: "Canvas is not supported.",
                }}
                videoReadyCallback={() => {
                  console.log("Video feed ready.");
                }}
              />
              Vui lòng chọn camera để chụp bill rõ nhất
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  // marginTop: "20px",
                }}
              >
                <select
                  className="select-cam-phone"
                  // defaultValue={devices[1]?.deviceId}
                  // value={devices[1]?.deviceId}
                  onChange={(event) => {
                    setActiveDeviceId(event.target.value);
                    // toast.success(event.target.value);
                  }}
                  placeholder="Chọn cam"
                >
                  <option value="" disabled>
                    Chọn cam
                  </option>
                  {devices.map((d) => {
                    return (
                      <>
                        {d.label === "camera2 0, facing back" ? (
                          <option key={d.deviceId} value={d.deviceId}>
                            Camera gần
                          </option>
                        ) : d.label === "camera2 2, facing back" ? (
                          <option key={d.deviceId} value={d.deviceId}>
                            Camera xa
                          </option>
                        ) : (
                          <option key={d.deviceId} value={d.deviceId}>
                            {d.label}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>

                <button
                  className="btn-webcam "
                  onClick={() => {
                    if (camera.current) {
                      setOpenCam(false);
                      setopenPopup(false);
                      const photo = camera.current.takePhoto();
                      console.log(photo);
                      setImage(photo);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginTop: "8px",
                      justifyContent: "center",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <img
                      src={CAM}
                      className="w-10"
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </button>
                <button
                  className="btn-cancel cam-hd1"
                  onClick={handleCancelCam}
                >
                  Hủy
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : ( */}
      <>
        {isShowModalErr ? (
          <CountDownOrder
            errMsg={errMsg}
            handlePopupErrorOk={handlePopupErrorOk}
          />
        ) : (
          <Fragment>
            {image !== undefined ? (
              <div>
                <img
                  src={image}
                  className="mt-5 rounded-3xl px-3"
                  alt="upload view"
                />

                <div className="flex justify-center gap-[12px] p-4">
                  <button
                    onClick={(e) => onClickDeleteImg(e)}
                    className="inline-block cursor-pointer rounded-[13px] border-0 bg-[#47CCFF] px-[32px] py-[15px] text-center font-[Montserrat-Light] text-[16px] font-black not-italic leading-5 text-[#130682] no-underline"
                  >
                    Chụp lại
                  </button>

                  <button
                    onClick={() => {
                      pushImageToGCS();
                    }}
                    className="inline-block cursor-pointer rounded-[13px] border-0 bg-[#0367C1] px-[32px] py-[15px] text-center font-[Montserrat-Light] text-[16px] font-black not-italic leading-5 text-[#FFFFFF] no-underline"
                  >
                    Xác nhận
                  </button>
                </div>
                {isAskLogin ? (
                  <ConfirmPopupLogin
                    image={GiftIconImg}
                    oneButton="oneButton"
                    titlePopup="Đăng ký thông tin để nhận phần quà hấp dẫn từ chúng tôi"
                    handleCancel={() => {
                      navigate("/login-no-register");
                    }}
                    handleOk={() => {
                      navigate("/login-no-register");
                    }}
                  />
                ) : null}
                <ModalGift
                  modalIsOpen={modalIsOpen}
                  soIds={so_ids}
                  listPrize={listPrize}
                ></ModalGift>
                <ConfirmPopupBtnHorizontal
                  isShow={isShowModalErr}
                  labelOk="Đồng ý"
                  message={errMsg}
                  handleOk={handlePopupErrorOk}
                />
              </div>
            ) : (
              <div className={`relative ${isUpload ? "is-upload" : ""} `}>
                <div className="bg-guide ">
                  <div className=" flex flex-col items-center bg-white pt-3  backdrop-blur-sm">
                    {imageFile !== undefined ? (
                      <div>
                        <div className=" mt-6 mb-1  flex justify-center  uppercase">
                          Xác nhận hóa đơn
                        </div>
                        <div className="takepic-img-wrap">
                          <img
                            src={URL.createObjectURL(imageFile)}
                            className="mt-4 px-[20px]"
                            alt="upload view"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* <div className="header-info uppercase mt-6 w-80 text-center leading-7 mb-1">
                      hướng dẫn chụp hóa đơn
                    </div> */}
                        <div className="flex w-full flex-col items-center justify-center px-5">
                          <div className="w-full">
                            <Carousel
                              className="style-carousel"
                              autoPlay
                              centerMode={false}
                              showArrows={true}
                              infiniteLoop={true}
                              showThumbs={false}
                              showStatus={false}
                              preventMovementUntilSwipeScrollTolerance={false}
                              renderIndicator={(
                                onClickHandler,
                                isSelected,
                                index,
                                label
                              ) => {
                                const defStyle = (
                                  <img src={ICON_DOTS} className="h-3 w-3" />
                                );
                                const defStylePrimary = (
                                  <img
                                    src={ICON_DOTS_PRIMARY}
                                    className="h-3 w-3"
                                  />
                                );
                                const style = isSelected
                                  ? { ...defStylePrimary }
                                  : { ...defStyle };
                                return (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      padding: "105px 4px",
                                    }}
                                    className="inline-block px-2"
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    aria-label={`${label} ${index + 1}`}
                                  >
                                    {style}
                                  </span>
                                );
                              }}
                            >
                              {images.map((item, index) => (
                                <div key={index}>
                                  <CarouselTakeAPhoto item={item} />
                                </div>
                              ))}
                            </Carousel>
                          </div>
                          <div className="mx-6 mt-[160px] flex w-full rounded-lg bg-[#ffffff]">
                            <div className="mt-3 flex w-16 items-start justify-center">
                              <img src={ICON_EXPLAIN} />
                            </div>
                            <div className="mt-3 w-full">
                              <div className="title-description-bill ">
                                Hóa đơn quá dài{" "}
                              </div>
                              <div className="content-description-bill mt-1">
                                Có thể gấp hóa đơn lại để hiển thị rõ phần tên
                                sản phẩm và số lượng. Không gấp hoặc che các
                                thông tin ở phần đầu và cuối hóa đơn
                              </div>

                              <div className="-ml-[52px] flex">
                                <div className="mt-3 flex w-16 items-start justify-center">
                                  <img src={P} />
                                </div>
                                <div className="content-description-bill ml-1 mt-1 mb-2">
                                  Liên hệ Hotline để được hướng dẫn chi tiết Số
                                  hotline{" "}
                                  <a
                                    href={`tel:${hotline}`}
                                    className="font-semibold"
                                  >
                                    {hotline}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-[15px] mb-[80px] flex justify-between">
                            <div className="color-button-disable btn-takephoto mr-2 ">
                              <div className="flex w-1 flex-col justify-center">
                                <i className="fa-solid fa-chevron-left fa-solid-back corlor-text-darkblack pl-5" />
                              </div>
                              <NavLink
                                to="/"
                                className="text-btn-cancel btn-text flex items-center justify-center"
                              >
                                Trở lại
                              </NavLink>
                            </div>
                            <div className="btn-takephoto ml-2 bg-[#96C61C] ">
                              <div className="ml-3 flex flex-col justify-center">
                                <img
                                  src={BTN_NEXT_GUIDE}
                                  className=" h-6 w-6"
                                />
                              </div>

                              <button
                                // onClick={() => openCamera()}
                                onClick={(e) => onClickUpload(e)}
                                className="text-btn-cancel btn-text corlor-text-white -ml-3 flex items-center justify-center"
                              >
                                Bỏ qua hướng dẫn
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {imageFile ? (
                      <div className="flex gap-[12px] p-4">
                        <button
                          onClick={(e) => onClickDeleteImg(e)}
                          className="color-button-enable inline-block cursor-pointer rounded-[13px] border-0 px-[32px] py-[15px] text-center font-[Montserrat-Light] text-[16px] font-black not-italic leading-5 text-[#130682] no-underline"
                        >
                          Chụp lại
                        </button>
                        <button
                          onClick={() => {
                            pushImageToGCS();
                          }}
                          className="inline-block cursor-pointer rounded-[13px] border-0 bg-[#00A1DF] px-[32px] py-[15px] text-center font-[Montserrat-Light] text-[16px] font-black not-italic leading-5 text-[#FFFFFF] no-underline"
                        >
                          Xác nhận
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      onChange={(e) => handleChangeImage(e)}
                      className="uploadFile"
                      style={{ display: "none" }}
                      id="upload"
                      type="file"
                      capture
                      accept="image/*"
                      ref={refInputUpload}
                    />
                  </div>
                  <ModalGift
                    modalIsOpen={modalIsOpen}
                    soIds={so_ids}
                    listPrize={listPrize}
                    status_lucky={status_lucky}
                  ></ModalGift>
                  {/* <ConfirmPopupBtnHorizontal
                isShow={isShowModalErr}
                labelOk="Đồng ý"
                message={errMsg}
                handleOk={handlePopupErrorOk}
              /> */}

                  {isAskLogin ? (
                    <ConfirmPopupLogin
                      oneButton="oneButton"
                      image={GiftIconImg}
                      titlePopup="Đăng ký thông tin để nhận phần quà hấp dẫn từ chúng tôi"
                      handleCancel={() => {
                        navigate("/login");
                      }}
                      handleOk={() => {
                        navigate("/login");
                      }}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </>
      {/* )} */}
    </>
  );
}

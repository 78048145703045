import { useQuery } from "react-query";
import IconZalo from "../../assets/fontawesome/image/logo_zalo.svg";
import { homeServices } from "../../services/apiService/homeServices";
export default function IconPhoneAndZalo() {
  const dataCampaign = useQuery({
    queryKey: ["getAllCampaign"],
    queryFn: () => homeServices.getRunningCampaignAll(),
  });
  console.log(dataCampaign);
  const handleClickPhone = (e) => {};

  const handleClickZalo = (e) => {};

  return (
    <div className="fixed bottom-10 right-3 z-20 w-[62px] h-[138px] flex flex-wrap justify-center max-[376px]:bottom-[50px]">
      <a href={"tel:" + dataCampaign?.data?.campaign_list[0]?.hotline}>
        <div className="container__contact-phonebgr flex justify-center items-center w-[62px] h-[76px] cursor-pointer"></div>
      </a>
      {/* <img src={IconZalo} className='text-center w-[54px] h-[54px] z-40 mt-[5px] cursor-pointer' onClick={e => handleClickZalo(e)} /> */}
    </div>
  );
}

import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { userDataLocal } from "../../services/localService/localService";
import { userServices } from "../../services/apiService/userServices";
import "../../assets/css/background.css";
import "../../assets/css/backgroundHeader.css";
import "../../assets/css/Login.css";
import "../../assets/css/Register.css";
import "../../assets/css/background__Footer.css";
import "../../assets/css/font-text.css";
import { addDays } from "date-fns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SubmitReceipt from "../SubmitReceipt/SubmitReceipt";
import { useSelector } from "react-redux";
import moment from "moment";
import { provinceLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
const style = {
  // backgroundColor: '#47CCFF',
  // color: '#130682',
  // fontSize: '16px',
  // fontHeight: '20px',
  // fontWeight: '400',
  width: "100%",
  backgroundColor: "#EEF5FC",
};

export default function RegisterComponent({ updateInfo }) {
  const navigation = useNavigate();
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  let { token } = userDataLocal.get();
  let dataUser = userDataLocal.get();
  console.log(dataUser);
  const nameData = localStorage.getItem("nameData");
  const [triggerSubmitReceipt, setTriggerSubmitReceipt] = useState(false);

  const revertDate = (value) => {
    var myDate = value.split("/");
    var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    console.log(newDate.getTime());
    return newDate.getTime();
  };
  const [userInfo, setUserInfo] = useState({});
  const [provinceData, setProvinceData] = useState([]);
  const [districData, setDistricData] = useState([]);
  const [defaultDistrict, setDefaultDistrict] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [provinceId, setProvinceId] = useState(76);
  const [districtID, setDistricID] = useState(4130);
  const [checkStatusBirth, setCheckStatusBirth] = useState(false);
  const [customerBirthday, setCustomerBirthday] = useState(null);

  const [checkAgree1, setCheckAgree1] = useState(false);
  const [checkAgree2, setCheckAgree2] = useState(false);

  console.log(checkAgree1);
  console.log(checkAgree2);

  const [customerSex, setCustomerSex] = useState("Male");
  useEffect(() => {
    if (userInfo) {
      setCustomerName(nameData);
      setCustomerEmail(userInfo.email);

      setCustomerSex(userInfo.gender);
    }
  }, [userInfo]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const [show, setShowMore] = useState(false);
  const showMore = () => {
    setShowMore(!show);
  };

  const onSubmit = (value) => {
    const data = {
      pnj_customer_id: dataUser.pnj_customer_id,
      name: customerName,
      email: "test@gmail.com",
      province_id: +provinceId,
      district_id: +districtID,
      gender: customerSex,
      birthday: "09/05/2000",
    };
    userServices
      .postUserInfo(data)
      .then((res) => {
        console.log(res);
        setAuthorization(token);
        const dataToken = {
          pnj_customer_id: dataUser.pnj_customer_id,
          token: token,
        };
        localStorage.setItem("nameData", data.name);
        const finalResult = Object.assign(dataToken, res);
        console.log(finalResult);
        userDataLocal.set(finalResult);
        if (gcsResult) {
          setTriggerSubmitReceipt(true);
        } else {
          setTimeout(() => {
            navigation("/");
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    setCustomerName(nameData);
    setCustomerEmail(dataUser?.email);
    setUserInfo(dataUser);
    setProvinceData(dataUser.province_list);
    setDistricData(dataUser.district_list);
    setDefaultDistrict(dataUser?.district_list);
    setProvinceId(dataUser.province_id);
    setDistricID(dataUser.district_id);
    if (getAge(customerBirthday) >= 18) {
      setCheckStatusBirth(true);
    } else {
      setCheckStatusBirth(false);
    }
  }, []);

  const handleProvinceChange = (value) => {
    setProvinceId(value.target.value);
  };
  const onChangeSex = (e) => {
    setCustomerSex(e.target.value);
  };
  const handleOnChangeDistrict = (value) => {
    setDistricID(value.target.value);
  };
  const handleChangeEmail = (value) => {
    setCustomerEmail(value.target.value);
  };

  function getAge(dateString) {
    console.log(dateString);
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age);
    if (age >= 18) {
      console.log("Được phép truy cập");
      setCheckStatusBirth(true);
      setCheckAgree(true);
      setCustomerBirthday(dateString);
    } else {
      setCustomerBirthday(dateString);
      toast.error("Vui lòng chọn ngày sinh từ 18 tuổi trở lên");
      setCheckStatusBirth(false);
      setCheckAgree(false);
    }
    return age;
  }
  console.log(checkStatusBirth);
  const handleChangeBirthday = (date) => {
    console.log(date);
    getAge(date);
  };
  const [checkAgree, setCheckAgree] = useState(false);
  const handleAgree = (e) => {
    if (e === "ag1") {
      setCheckAgree1(!checkAgree1);
    } else if (e === "ag2") {
      setCheckAgree2(!checkAgree2);
    }
  };
  // const handleClickCheck = (e) => {
  //   console.log(e);
  //   if (checkStatusBirth) {
  //     console.log("sinh ddungs");
  //     setCheckAgree(!checkAgree);
  //   } else {
  //     toast.error("Vui lòng chọn ngày sinh từ 18 tuổi trở lên");
  //     setCheckAgree(false);
  //   }
  // };
  const handleOnchangeName = (event) => {
    setCustomerName(event.target.value);
  };
  const filterDistrictByProvinceID = (id) => {
    let cloneList = [...defaultDistrict];
    cloneList = cloneList.filter((province) => {
      return id === province.province_id;
    });

    setDistricData(cloneList);
  };
  useEffect(() => {
    filterDistrictByProvinceID(+provinceId);
  }, [provinceId, userInfo]);
  return (
    <>
      <form className="form_register mt-5" onSubmit={handleSubmit(onSubmit)}>
        <input
          className="form__name input-size input-data "
          placeholder="Nhập tên của bạn"
          {...register("name")}
          style={style}
          value={customerName || ""}
          onChange={(e) => {
            handleOnchangeName(e);
          }}
        />
        <input
          className="form__phoneField input-size input-data bg-[#bdbdbd] my-10"
          placeholder="Số điện thoại "
          readOnly
          {...register("phone")}
          value={userInfo?.phone || ""}
          style={{
            color: "#333333",
            fontSize: "16px",
            fontHeight: "20px",
            fontWeight: "400",
          }}
        />
        {/* <input
          className="form__email input-size font-normal input-data "
          placeholder="Nhập email của bạn "
          {...register("email")}
          onChange={(e) => {
            handleChangeEmail(e);
          }}
          value={customerEmail || ""}
          style={style}
        /> */}
        {/* <select
          className="select-wrapper select_city my-10  input-size pr-6 font-normal input-data"
          onChange={(e) => handleProvinceChange(e)}
          style={style}
          value={provinceId || ""}
        >
          <option value="-1" disabled defaultValue className="pr-5">
            Nhập tỉnh/thành phố của bạn
          </option>
          {provinceData?.map((province) => (
            <option value={province.id} key={province.id}>
              {province.name}
            </option>
          ))}
        </select>
        <select
          className="select-wrapper select_province input-size font-normal  mb-10 input-data"
          onChange={(e) => {
            handleOnChangeDistrict(e);
          }}
          value={districtID || ""}
          style={style}
        >
          <option value="-1" style={{ color: "#BDBDBD" }} disabled>
            Nhập Quận/Huyện của bạn
          </option>

          {districData?.map((district) => (
            <option value={district.id} key={district.id}>
              {district.name}
            </option>
          ))}
        </select> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "calc(100%)",
          }}
        >
          <DatePicker
            className="datePicker corlor-text-darkblack font-regular h-[60px] rounded-[15px] pl-5 text-[13px] w-full bg-[#EEF5FC]"
            placeholderText="Ngày sinh"
            selected={customerBirthday ? customerBirthday : null}
            onChange={(date) => {
              handleChangeBirthday(date);
            }}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            disabledKeyboardNavigation
            dropdownMode="select"
            maxDate={addDays(new Date(), -1)}
            onKeyDown={(e) => {
              e.preventDefault();
              return false;
            }}
            onFocus={(e) => e.target.blur()}
          />
          <select
            className="form__sex font-regular h-[60px] w-full select-wrapper "
            onChange={(e) => onChangeSex(e)}
            style={{
              fontSize: "13px",
              width: "50%",
              color: "#757575",
              borderRadius: "15px",
              borderColor: "white",
              marginLeft: "15px",
              paddingLeft: "20px",

              backgroundColor: "#EEF5FC",
            }}
            value={customerSex || ""}
          >
            <option value="Giới tính" key="sex" disabled defaultValue>
              Giới tính
            </option>
            <option value="male" key="male">
              Nam
            </option>
            <option value="female" key="female">
              Nữ
            </option>
          </select>
        </div> */}

        {show === true ? (
          <>
            <div
              className={`${
                updateInfo ? "corlor-text-darkblack" : "corlor-text-white"
              } text-[12px] mt-[5px] font-regular leading-5 whitespace-pre-line flex flex-wrap break-words`}
            >
              <div style={{ marginTop: "8px" }}>
                Là một phần của chương trình này, P&G sẽ chia sẻ dữ liệu cá nhân
                của bạn với đơn vị cung cấp dịch vụ được P&G ủy quyền, các công
                ty thuộc tập đoàn P&G, và/hoặc chuyển dữ liệu cá nhân của bạn
                đến một địa điểm bên ngoài Việt Nam cho các mục đích xử lý được
                mô tả bên dưới. P&G không bán dữ liệu cá nhân của bạn cho bên
                thứ ba. Bạn quyết định việc đánh dấu vào các ô bên dưới và đồng
                ý cho chúng tôi sử dụng dữ liệu cá nhân của bạn. Tuy nhiên, lựa
                chọn từ chối của bạn có thể ảnh hưởng đến việc nhận sản
                phẩm/dịch vụ mà chúng tôi cung cấp theo chương trình, cũng như
                giới hạn trải nghiệm mà bạn có được khi tham gia chương trình
                này.
              </div>
              <div className="checkbox-tc">
                <div className="checkbox-tc" style={{ marginBottom: "8px" }}>
                  <div className="m-[0] flex font-light">
                    <div>
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        defaultChecked={checkAgree1}
                        onClick={(e) => handleAgree("ag1")}
                        className="checkbox-confirm-register w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                    <label
                      htmlFor="check"
                      className="text-[#333333] mr-[10px] font-[Montserrat] not-italic font-normal text-[13px] leading-[20px]"
                    >
                      {" "}
                    </label>
                    <label
                      htmlFor="check"
                      className={`${
                        updateInfo
                          ? "corlor-text-darkblack"
                          : "corlor-text-white"
                      } mr-[10px] font-regular not-italic font-normal text-[13px] leading-[20px]`}
                    >
                      Tôi đồng ý
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "12px" }}>
                P&G Việt Nam và đơn vị cung cấp dịch vụ của P&G có thể xử lý dữ
                liệu cá nhân của tôi nhằm mục đích gửi cho tôi thông tin quảng
                bá, tiếp thị về các sản phẩm, thông tin chương trình khuyến mại
                và sự kiện của P&G. Các thông tin này sẽ được gửi qua email, tin
                nhắn với tần suất tối đa 4 lần/tháng. Sự đồng ý của tôi dưới đây
                sẽ thay thế các lựa chọn từ chối quảng cáo trước đó (bao gồm cả
                việc tôi đã đăng ký danh sách không nhận cuộc gọi quảng cáo "Do
                not call list”), và tôi sẽ thông báo cho P&G biết nếu tôi muốn
                từ chối nhận quảng cáo.
              </div>
              <div className="checkbox-tc" style={{ marginBottom: "8px" }}>
                <div className="m-[0] flex font-light">
                  <div>
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      defaultChecked={checkAgree2}
                      onClick={(e) => handleAgree("ag2")}
                      className="checkbox-confirm-register w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <label
                    htmlFor="check"
                    className="text-[#333333] mr-[10px] font-[Montserrat] not-italic font-normal text-[13px] leading-[20px]"
                  >
                    {" "}
                  </label>
                  <label
                    htmlFor="check"
                    className={`${
                      updateInfo ? "corlor-text-darkblack" : "corlor-text-white"
                    } mr-[10px] font-regular not-italic font-normal text-[13px] leading-[20px]`}
                  >
                    Tôi đồng ý
                  </label>
                </div>
              </div>
              <div style={{ marginTop: "0px" }}>
                Chính sách bảo mật của chúng tôi có sẵn tại{" "}
                <span
                  className=""
                  style={{
                    color: `${updateInfo ? "#003DA5" : "#47CCFF"}`,
                  }}
                >
                  https://privacypolicy.pg.com/vi/
                </span>{" "}
                và áp dụng cho việc thu thập, sử dụng và tiết lộ dữ liệu cá nhân
                của bạn. Bằng cách đánh dấu vào các ô ở trên, bạn đồng ý cho P&G
                được thu thập, sử dụng, xử lý và chuyển dữ liệu cá nhân của bạn
                theo Chính sách quyền riêng tư của chúng tôi.{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: `${updateInfo ? "#003DA5" : "#47CCFF"}`,
                  }}
                  onClick={() => showMore()}
                >
                  {show ? "(Rút gọn)" : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          <span
            className={`${
              updateInfo ? "corlor-text-darkblack" : "corlor-text-white"
            } text-[12px]  font-regular leading-5 whitespace-pre-line flex flex-wrap break-words mt-5`}
          >
            Là một phần của chương trình này, P&G sẽ chia sẻ dữ liệu cá nhân của
            bạn với đơn vị cung cấp dịch vụ được P&G ủy quyền, các công ty thuộc
            tập đoàn P&G, và/hoặc chuyển dữ liệu cá nhân của bạn đến một địa
            điểm bên ngoài Việt Nam cho các mục đích xử lý được mô tả bên dưới.
            P&G không bán dữ liệu cá nhân của bạn cho bên thứ ba.
            <span
              style={{
                cursor: "pointer",
                // padding: "5px 0 0 10px",
                color: `${updateInfo ? "#003DA5" : "#47CCFF"}`,
                // "#003DA5",
              }}
              onClick={() => showMore()}
            >
              {show ? "" : "(Xem thêm)"}
            </span>
          </span>
        )}
        {checkAgree1 && checkAgree2 && checkAgree ? (
          <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
            <input
              type="submit"
              className="color-button-enable font-bold border-0 text-[#130682] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] leading-5"
              value={`${updateInfo ? "Lưu lại thông tin" : "Đăng nhập"}`}
            />
          </div>
        ) : (
          <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
            <input
              type="button"
              className="color-button-disable font-bold border-0 text-[#33333] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light]  leading-5"
              value={`${updateInfo ? "Lưu lại thông tin" : "Đăng nhập"}`}
            />
          </div>
        )}
      </form>
      <SubmitReceipt trigger={triggerSubmitReceipt}></SubmitReceipt>
    </>
  );
}

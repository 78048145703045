import "./styleqr.css";
import { useState } from "react";
import QrReader from "react-qr-reader";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

const QRCodeDoiqua = ({ setData, setCam }) => {
    const [code, setCode] = useState(null);
    const [showDialog, setDiaglog] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [precScan, setPrecScan] = useState("");
    const [selected, setSelected] = useState("environment");
    const [errorMessage, setErrorMessage] = useState(null);

    async function fetchData({ qr = "" }) {
        try {
            const result = await axios.get(
                `https://ucs-goma-backend.herokuapp.com/payement/scan?matricule=${qr}&forThisYear=1`
            );
            console.log("scanned code", qr);
            const { message, payement } = result.data;
            console.log(payement);
            if (!message) {
                setCode({
                    text: payement.matricule,
                    identite: `${payement.nom} ${payement.postnom} ${payement.prenom}`,
                    promotion: payement.auditoire,
                    annee: payement.annee,
                    frais: Number.parseFloat(payement.totalPayer),
                    total: Number.parseFloat(payement.totalAPayer),
                    recouvrement: "Premiere tranche",
                    maxEncours: 800,
                });
                // setPrecScan(null);
                setDiaglog(true);
                // setCam(true);
            } else {
                setCam(true);
                setCode(null);
                setPrecScan(null);
                setErrorMessage(message);
                setDiaglog(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleScan = async (scanData) => {
        console.log(`loaded data data`, scanData);
        // toast.success(scanData);
        setData(scanData);
        if (scanData) {
            setCam(true);
        }
        if (scanData && scanData !== "" && !showDialog && !processing) {
            console.log(`loaded >>>`, scanData);
            // setPrecScan(scanData);
            await fetchData({ qr: scanData });
        }
    };
    const handleError = (err) => {
        console.error(err);
    };
    return (
        <div className="App flex justify-center">
            {/* {code && <h2>{code.text}</h2>} */}

            <QrReader
                facingMode={selected}
                delay={500}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: "100%", maxWidth: "430px", heigth: "100px" }}
            />
        </div>
    );
};

export default QRCodeDoiqua;

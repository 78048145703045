import React from "react";
import { NavLink } from "react-router-dom";

export default function CarouselTopItem({ item }) {
  return (
    <NavLink to={`/deal-details/${item.id}`}>
      <div>
        <img
          src={item.campaign_banner}
          className="w-full max-h-[300px] rounded-2xl bg-center bg-white bg-cover duration-500"
        />
      </div>
    </NavLink>
  );
}

import Modal from "react-modal";
import Image from "../../assets/fontawesome/image/cancel-phone-icon-success.png";

export default function ComponentNotify({
  isShow,
  labelOk = "Đồng ý",
  handleOK = () => {},
  message,
}) {
  return (
    <Modal
      isOpen={isShow}
      contentLabel="Example Modal"
      ariaHideApp={false}
      className="z-50"
    >
      <div className="popup-confirm-phone absolute top-[25%] z-50 mx-4 h-[70px] w-[92%] ">
        <div className="z-50 mt-[40px] w-full rounded-[30px] border-2 border-solid bg-white">
          <img
            src={Image}
            className="image__phone relative top-0 left-1/2 z-50"
          />
          <div className="z-50 flex flex-col items-center justify-center">
            <div className="z-50 -mt-9 text-[24px] text-[#014FA2]">
              THÔNG BÁO
            </div>
            <div className="z-50 my-5 w-full px-10 text-center text-[13px]">
              {message}
            </div>
            <div className="mb-3 flex justify-around">
              <button
                className="color-button-blue hotline-btn corlor-text-white ml-2 flex
                            h-12 w-36 items-center justify-center gap-1 rounded-[16px] px-[14px] py-[12px] font-semibold"
                onClick={handleOK}
              >
                {labelOk}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

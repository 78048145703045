import "../../assets/css/backgroundHeader.css";
import "../../assets/css/background.css";
import "../../assets/css/backgroundNotifyGift.css";
import Image from "../../assets/fontawesome/image/gift.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import "../../assets/css/backgroundButton.css";
import { useSelector } from "react-redux";
import { userServices } from "../../services/apiService/userServices";
import { useDispatch } from "react-redux";
import { setProvince, setUserData } from "../../Redux/Action/userAction";
import {
  provinceLocal,
  userDataLocal,
} from "../../services/localService/localService";
import Countdown from "react-countdown";
import { setAuthorization } from "../../services/apiService/configURL";
import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import SubmitReceipt from "../../component/SubmitReceipt/SubmitReceipt";
import { useRef } from "react";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
let titlePopup = "Đăng kí thông tin để nhận phần quà hấp dẫn từ chúng tôi";
let redirectPathOk = "/register";
let redirectPathLater = "/";
const styleButton = {
  color: "#333",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
};
const styleButtonOk = {
  color: "#fff",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
};
let ENTER_MINUTES = 60000;
function ConfirmOTP() {
  document.body.style.backgroundColor = "black";
  const dispatch = useDispatch();
  let minutes_limit = useSelector((state) => {
    return state.userReducer.userData;
  });

  let phone_data = localStorage.getItem("phoneData");
  let name_data = localStorage.getItem("phoneData");
  let { token } = userDataLocal.get();
  const lastModify = localStorage.getItem("lastModify");
  const navigation = useNavigate();
  let [otp, setOtp] = useState(0);
  let [OTPParams, setOTPParams] = useState({});
  const [showNotify, setShowNotify] = useState(false);

  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  const [triggerSubmitReceipt, setTriggerSubmitReceipt] = useState(false);
  const handleUpdateInfoLater = () => {
    if (gcsResult) {
      setTriggerSubmitReceipt(true);
    } else {
      navigation("/");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (token) {
      navigation("/");
    }
  }, []);
  const handleBack = (e) => {
    console.log(1);
    navigation("/login");
  };

  const handleClickShowInfoGift = () => {
    userServices
      .postValidateOTP(OTPParams)
      .then((res) => {
        if (gcsResult) {
          setTriggerSubmitReceipt(true);
          // setPopup(true);
          // localStorage.setItem("phoneData", data.phone);
          // localStorage.setItem("nameData", data.name);
        } else {
          navigation("/");
        }
        console.log(res);
        setAuthorization(res.token);
        userDataLocal.set(res);
        dispatch(setUserData(res));
        // setTimeout(() => {
        //     toast.success("Xác thực OTP thành công!");
        // }, 1000)
        // setShowNotify(true);
      })
      .catch((e) => {
        toast.error(e);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const handleClose = (e) => {
    // console.log(OTPParams);
    if (lastModify) {
      navigation("/takephoto");
    } else {
      navigation("/");
    }
  };

  const handleChangeOtp = (e) => {
    // console.log(e)
    setOtp(e);
    setOTPParams({ otp: e });
  };
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const resendOTP = () => {
    const phoneFormat = {
      name: name_data,
      phone: phone_data,
    };
    userServices
      .postUserLogin(phoneFormat)
      .then((res) => {
        userDataLocal.set(res);
        dispatch(setUserData(res));
        toast.success("Gửi lại thành công");
        navigation("/confirm-otp");
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        console.log("finally");
      });
    setMinutes(3);
    setSeconds(0);
  };

  return (
    <>
      {triggerSubmitReceipt ? (
        <SubmitReceipt trigger={triggerSubmitReceipt} />
      ) : (
        <div className="bg_default container box-border flex h-full min-h-screen w-screen min-w-full flex-col px-[25px] text-[#fff]">
          <header
            className="icon-back z-50 mt-[7%] flex h-8 w-full max-w-full items-center py-[36px] opacity-100"
            onClick={(e) => handleBack(e)}
          >
            <i className="fa-solid fa-chevron-left fa-solid-back"></i>
          </header>
          <header className="h-9 w-52 p-0 font-[Montserrat] text-2xl font-bold not-italic leading-9">
            ĐĂNG NHẬP
          </header>
          <div className="container__login-item  w-[87%] p-[16px_0_68px_0] text-base font-light text-[#fff]">
            Một mã xác định gồm 6 chữ số đã gửi đến số điện thoại
            <span className="color-phone"> {phone_data}</span>
          </div>
          <div className="container__input">
            <div className="container__input-otp mt-3 -mb-6 flex flex-col items-center justify-center">
              <OtpInput
                className="otp-element text-[#333]"
                value={otp}
                onChange={handleChangeOtp}
                numInputs={6}
                isInputNum={true}
                separator={<span> </span>}
              />
            </div>
            <div
              className="mb-16 flex justify-center font-light text-[#fff]"
              style={{ marginTop: "10px" }}
            >
              Không nhận được mã
              <span className="" style={{ color: "#3599E9" }}>
                <span>
                  <div className="countdown-text">
                    {seconds > 0 || minutes > 0 ? (
                      <>
                        <p>
                          <span> </span>
                          {minutes < 10 ? ` 0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      </>
                    ) : (
                      <button
                        disabled={seconds > 0 || minutes > 0}
                        className=" mx-1.5 text-[#3599e9]"
                        onClick={resendOTP}
                      >
                        Gửi lại
                      </button>
                    )}
                  </div>
                </span>
              </span>
            </div>
            <div className="box-border flex justify-center py-[56px] font-light text-[#333]">
              {otp?.length === 6 ? (
                <input
                  style={styleButton}
                  id="button__D"
                  type="button"
                  className=" color-button-enable text-buttonOTP "
                  value="Đăng nhập"
                  onClick={() => handleClickShowInfoGift()}
                />
              ) : (
                <input
                  style={styleButton}
                  id="button__D"
                  type="button"
                  className=" color-button-disable text-buttonOTP "
                  value="Đăng nhập"
                />
              )}
            </div>
          </div>

          {showNotify === true ? (
            <ConfirmPopupLogin
              image={Image}
              titlePopup={titlePopup}
              onClosePopup={() => {
                setShowNotify(false);
              }}
              handleCancel={() => {
                handleUpdateInfoLater();
              }}
              handleOk={() => {
                navigation("/register");
              }}
            />
          ) : null}
        </div>
      )}

      <FooterLogo />
    </>
  );
}
export default ConfirmOTP;

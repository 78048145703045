import React, { useEffect, useState } from "react";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import wheel_text from "../../assets/fontawesome/image/wheel-text-x.png";
import KIM_QUAY from "../../assets/kimquay-x.png";
import spin_img from "../../assets/fontawesome/image/rotaryNeedle-x.png";
import "./SpinTheWheel.css";
import "./wheel.css";
import { useNavigate, useParams } from "react-router";
import { luckyDrawService } from "../../services/apiService/LuckyDraw";
import { useDispatch } from "react-redux";
import logo_png from "../../assets/fontawesome/image/logo_png.png";

import { toast } from "react-toastify";
import AnnouncePrize from "../AnnouncePrize/AnnouncePrize";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import SelectGift from "../SelectGift/SelectGift";
const style = {
  // backgroundColor: '#47CCFF',
  // color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};
export default function SpinTheWheel() {
  const [segments, setSegments] = useState([]);
  const [spinRemain, setSpinRemain] = useState(0);
  const [newGiftList, setNewGiftList] = useState([]);
  const [listPrize, setListPrize] = useState([]);
  const [giftWin, setGiftWin] = useState([]);
  const [giftListWin, setGiftListWin] = useState([]);
  const [transformBox, settransformBox] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [isAnnounce, setIsAnnounce] = useState(false);
  const [severGiftList, setSeverGiftList] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  };
  const shuffleGiftList = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const createNewGiftArrayForWheel = (severGiftList) => {
    console.log(severGiftList);
    let newGiftList = [];

    if (severGiftList.length > 8) {
      severGiftList = severGiftList.slice(0, 8);
    }
    let notBigGiftList = severGiftList.filter((gift) => {
      return (
        gift.gift_code !== "purite_biggift_nhanvangsbj0.5chi" &&
        gift.gift_code !== "purite_biggift_mayruamathalio"
        // gift.gift_code === "Evoucher100k" || gift.gift_code === "cbmmls"
      );
    });
    console.log(notBigGiftList);
    let bigGiftList = severGiftList.filter((gift) => {
      return (
        gift.gift_code === "purite_biggift_nhanvangsbj0.5chi" ||
        gift.gift_code === "purite_biggift_mayruamathalio"
      );
    });
    console.log(bigGiftList);
    if (severGiftList.length < 8) {
      let lackLenght = 8 - severGiftList.length;
      while (lackLenght > 0) {
        notBigGiftList = [
          ...notBigGiftList,
          notBigGiftList[getRandomInt(0, notBigGiftList.length)],
        ];
        console.log(notBigGiftList);
        lackLenght--;
      }
    }

    newGiftList = [...shuffleGiftList([...bigGiftList, ...notBigGiftList])];

    setNewGiftList(newGiftList);

    return newGiftList;
  };
  const getDataGift = (severGiftList) => {
    let list_prizes = createNewGiftArrayForWheel(severGiftList);
    console.log(list_prizes);
    list_prizes = list_prizes.map(function (item, index) {
      return {
        id: index + 1,
        text: item.gift,
        img: item.gift_image,
      };
    });

    setSegments(list_prizes);
  };
  const [popupSelectGift, setPopupSelectGift] = useState(false);
  const [amountListWin, setAmountListWin] = useState();
  useEffect(() => {
    // const select_gift = JSON.parse(localStorage.getItem("SELECT_GIFT"));
    // console.log(select_gift);
    console.log(id);
    // if (select_gift.gift_no !== 0) {
    //   setPopupSelectGift(true);
    // } else {
    //   setPopupSelectGift(false);
    // }
    luckyDrawService
      .postDrawLuckyGift(id)
      .then((res) => {
        setAmountListWin(res.list_win.length);
        console.log(res);
        setSpinRemain(res.gift_no - res.count_draw);
        getDataGift(res.list_prizes);
        setSeverGiftList(res.list_prizes);
        setGiftListWin(res.list_win);
        let spinRemain = res.gift_no - res.count_draw;
        if (spinRemain <= 0) {
          // setTimeout(() => {
          navigate("/finish");
          // }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
        setIsDisable(true);
        toast.error("Đã xảy ra lỗi! Thử lại sau....");
        setTimeout(() => {
          navigate("/list-rotation");
        }, 3000);
      });
  }, []);
  const runWheel = () => {
    if (amountListWin === 0) {
      toast.error("Vòng quay lỗi vui lòng liên hệ lại tổng đài");
      navigate("/list-rotation");
    } else {
      console.log(1);
      setIsDisable(true);
      luckyDrawService
        .postCountDraw(id)
        .then((res) => {
          console.log(res);
          let spinRemain = res.gift_no - res.count_draw;
          console.log(spinRemain);

          setSpinRemain(spinRemain);
          let array_angle = [
            { id: 1, angle: 0 },
            { id: 2, angle: -45 },
            { id: 3, angle: -90 },
            { id: 4, angle: -135 },
            { id: 5, angle: -180 },
            { id: 6, angle: -225 },
            { id: 7, angle: -270 },
            { id: 8, angle: -315 },
          ];

          let indexWinnigGift = giftListWin.findIndex((item) => {
            return res.count_draw === item?.index;
          });

          console.log(indexWinnigGift);
          console.log(newGiftList);
          let indexNewGiftList = newGiftList.findIndex((item) => {
            return giftListWin[indexWinnigGift]?.gift_code === item?.gift_code;
          });
          console.log(indexNewGiftList);
          let winningItem = {
            // ...newGiftList[indexNewGiftList],
            gift_code: giftListWin[indexWinnigGift]?.gift_code,
            spinRemain: spinRemain,
            checksum: giftListWin[indexWinnigGift]?.voucher_line_id,
            so_id: +id,
            gift: giftListWin[indexWinnigGift]?.gift,
            gift_image: giftListWin[indexWinnigGift]?.gift_image,
            is_physical_gift: giftListWin[indexWinnigGift]?.is_physical_gift,
            conditions_receiving_prize:
              giftListWin[indexWinnigGift]?.conditions_receiving_prize,
          };
          console.log(winningItem);
          localStorage.setItem("WINNING_GIFT", JSON.stringify(winningItem));
          let angle = array_angle[indexNewGiftList]?.angle;
          console.log(angle);
          settransformBox("rotate(" + (angle + 360 * 10) + "deg)");
          setTimeout(() => {
            handleChangeAnnounceStatus();
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            navigate("/list-rotation");
          }, 3000);
          setIsDisable(false);
        });
    }
  };
  const handleChangeAnnounceStatus = () => {
    setIsAnnounce(!isAnnounce);
    settransformBox("rotate(" + 0 + "deg)");
  };
  const handleEnableBtn = () => {
    setIsDisable(!isDisable);
    getDataGift(severGiftList);
  };
  return (
    <div>
      {popupSelectGift ? (
        <SelectGift setPopupSelectGift={setPopupSelectGift} />
      ) : (
        <div
          className={`spin-the-wheel contain  overflow-hidden ${
            isAnnounce ? "hidden" : "block"
          }`}
        >
          <div className="">
            <div className="wheel">
              <div id="wheel" className="">
                <div className="wheel-container-vongquay">
                  <div className="wheel-text flex justify-center">
                    {/* <div className="absolute z-40 -top-7">
                      <img className="" src={logo_png} alt="" />
                    </div> */}
                    <img className=" mt-1" src={wheel_text} alt="" />
                    <div className="absolute -bottom-24 z-50 flex justify-center">
                      <img src={KIM_QUAY} />
                    </div>
                  </div>
                  <>
                    <div id="bg" className="bg">
                      <div id="mainbox" className="mainbox">
                        <div
                          id="box"
                          className="box nenvongquay "
                          style={{ transform: `${transformBox}` }}
                        >
                          <div className="box1 ">
                            <span className="segment span1 color-1">
                              <i id="id_7" className="item7 segments">
                                <h6>{segments[6]?.text}</h6>
                                <img
                                  src={segments[6]?.img}
                                  alt={segments[6]?.text}
                                />
                              </i>
                            </span>
                            <span className="segment span2 color-1">
                              <i id="id_3" className="item3 segments">
                                <h6>{segments[2]?.text}</h6>
                                <img
                                  src={segments[2]?.img}
                                  alt={segments[2]?.text}
                                />
                              </i>
                            </span>
                            <span className="segment span3 color-1">
                              <i id="id_5" className="item5 segments">
                                <h6>{segments[4]?.text}</h6>
                                <img
                                  src={segments[4]?.img}
                                  alt={segments[4]?.text}
                                />
                              </i>
                            </span>
                            <span className="segment span4 color-1">
                              ?
                              <i id="id_1" className="item1 segments">
                                <h6>{segments[0]?.text}</h6>
                                <img
                                  src={segments[0]?.img}
                                  alt={segments[0]?.text}
                                />
                              </i>
                            </span>
                          </div>
                          <div className="box2">
                            <span className="segment span1 color-2">
                              <i id="id_4" className="item4 segments">
                                <h6>{segments[3]?.text}</h6>
                                <img
                                  src={segments[3]?.img}
                                  alt={segments[3]?.text}
                                />
                              </i>
                            </span>
                            <span className="segment span2 color-2">
                              <i id="id_8" className="item8 segments">
                                <h6>{segments[7]?.text}</h6>
                                <img
                                  src={segments[7]?.img}
                                  alt={segments[7]?.text}
                                />
                              </i>
                            </span>
                            <span className="segment span3 color-2">
                              <i id="id_2" className="item2 segments">
                                <h6>{segments[1]?.text}</h6>
                                <img
                                  src={segments[1]?.img}
                                  alt={segments[1]?.text}
                                />
                              </i>
                            </span>
                            <span className="segment span4 color-2">
                              <i id="id_6" className="item6 segments">
                                <h6>{segments[5]?.text}</h6>
                                <img
                                  src={segments[5]?.img}
                                  alt={segments[5]?.text}
                                />
                              </i>
                            </span>
                          </div>
                        </div>

                        <button
                          id="buttonRun"
                          className="spin z-20 "
                          onClick={() => {
                            runWheel();
                          }}
                          disabled={isDisable ? "disabled" : ""}
                        >
                          <img
                            className="-z-10 w-[60px]"
                            src={spin_img}
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="box-border flex justify-center py-[0px] font-light text-[#ffffff]">
                <button
                  style={style}
                  type="button"
                  className="color-button-wheel rounded-[16px] px-[20px] py-[20px] text-[16px] text-[#ffffff] "
                >
                  {spinRemain >= 1
                    ? `Bạn còn ${spinRemain} lượt quay`
                    : "Bạn đã hết lượt quay"}
                </button>
              </div>
            </div>
          </div>
          <IconPhoneAndZalo />
          <FooterLogo />
        </div>
      )}

      <div className={`${isAnnounce ? "block" : "hidden"}`}>
        <AnnouncePrize
          handleChangeAnnounceStatus={handleChangeAnnounceStatus}
          handleEnableBtn={handleEnableBtn}
        />
      </div>
    </div>
  );
}

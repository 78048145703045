import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import product from "../../assets/fontawesome/image/image_product.svg";
import "../../assets/css/participateHistory.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import FILTER from "../../assets/fontawesome/image/filter.svg";
import ParticipateHisItem from "./ParticipateHisItem";
import { useEffect, useState } from "react";
import { historyService } from "../../services/apiService/historyServices";
import Logo from "../../assets/fontawesome/image/logo_mvc.png";
import { useLocation } from "react-router-dom";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
const TITLE = "Lịch sử tham gia";
export default function ParticipateHistory() {
  const location = useLocation();
  const back = location.pathname.split("/")[2];
  console.log(back);

  const [listHistory, setListHistory] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    historyService
      .getListHistory()
      .then((res) => {
        console.log(res);
        setListHistory(res.history);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div>
      <HeaderBackground
        TITLE={TITLE}
        buttonBack={`${back === "tick" ? "/infor-customer" : "/"}`}
        // filter={FILTER}
      />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[86%] w-full z-10">
        <ul className="containerNotify__background-list pt-8 box-border z-20 ">
          {listHistory.map((lstProduct, index) =>
            index % 2 === 0 ? (
              <li className="participatehis-item bg-[#f0f0f0]" key={index}>
                <ParticipateHisItem lstProduct={lstProduct} />
              </li>
            ) : (
              <li className="participatehis-item " key={index}>
                <ParticipateHisItem lstProduct={lstProduct} />
              </li>
            )
          )}
          <div className="h-24"></div>
        </ul>
        <FooterLogo />
      </div>

      <IconPhoneAndZalo />
    </div>
  );
}

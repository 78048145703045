import React, { useEffect, useState } from "react";
import logo_png from "../../assets/fontawesome/image/logo_png.png";
import "./SelectGift.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { NavLink, useNavigate } from "react-router-dom";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import TRU from "../../assets/fontawesome/image/tru.png";
import CONG from "../../assets/fontawesome/image/cong.png";
import classNames from "classnames";
import { luckyDrawService } from "../../services/apiService/LuckyDraw";

const style = {
  // backgroundColor: '#47CCFF',
  color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};

export default function SelectGift({
  handleChangeAnnounceStatus,
  handleEnableBtn,
  setPopupSelectGift,
}) {
  const select_gift = JSON.parse(localStorage.getItem("SELECT_GIFT"));

  const [numberTop, setNumberTop] = useState(0);
  const [numberDown, setNumberDown] = useState(0);
  const [numberCurrent, setNumberCurrent] = useState(select_gift?.gift_no);
  const winningGift = JSON.parse(localStorage.getItem("WINNING_GIFT"));
  const [isShorten, setIsShorten] = useState(true);
  const navigate = useNavigate();
  const handleShortingText = () => {
    setIsShorten(!isShorten);
  };
  console.log(select_gift);
  const handleNavigateSucess = () => {
    console.log("hello");
    console.log(select_gift);

    Object.assign(select_gift?.select_gift_list[0], { qty: numberTop });
    Object.assign(select_gift?.select_gift_list[1], { qty: numberDown });
    console.log(select_gift?.select_gift_list);
    console.log(numberDown);
    const data = {
      select_gift_list: select_gift?.select_gift_list,
    };
    console.log(data);
    luckyDrawService
      .postSelectGift(data)
      .then((res) => {
        console.log(res);
        setPopupSelectGift(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeNumber = (e) => {
    if (e === "-") {
      if (numberTop === 0) {
        setNumberTop(0);
      } else {
        setNumberTop(numberTop - 1);
      }
    } else {
      if (numberTop === numberCurrent) {
        setNumberTop(numberCurrent);
      } else {
        setNumberTop(numberTop + 1);
      }
    }
  };
  const handleChangeNumberDown = (e) => {
    if (e === "-") {
      if (numberDown === 0) {
        setNumberDown(0);
      } else {
        setNumberDown(numberDown - 1);
      }
    } else {
      if (numberDown === numberCurrent) {
        setNumberDown(numberCurrent);
      } else {
        setNumberDown(numberDown + 1);
      }
    }
  };
  return (
    <>
      <div className="">
        <div className="annouce-prize text-[#FFFFFF] contain">
          <div className="pt-[20px]">
            <div className="flex justify-center flex-col items-center">
              <div>
                <img className="" src={logo_png} alt="" />
              </div>
              <div className="text-center">
                <h1 className="uppercase my-[26px] text-[28px]">
                  bạn có {numberCurrent} phần quà
                </h1>
                <p className="font-light px-10">
                  Lưu ý: Tổng số lượng phần quà không vượt quá số hệ thống thông
                  báo bên trên
                </p>
                <span></span>
              </div>
            </div>
          </div>
          <div className="mt-12 px-8 w-full">
            <div className="bg-white w-full rounded-2xl">
              <div className="grid grid-cols-10 text-black pt-3 pl-3">
                <div className="col-span-3 w-20">
                  <img src={select_gift?.select_gift_list[0].gift_image} />
                </div>
                <div className="col-span-7">
                  <div className="text-[16px]">
                    {select_gift?.select_gift_list[0].gift}
                  </div>
                  <div>
                    còn lại:{" "}
                    <span className="text-[#014FA2]">
                      {select_gift?.select_gift_list[0].remaining}
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-black flex justify-end pb-1 pr-5">
                <div className=" shadow-btnnumber pb-2 flex">
                  <div className="px-2 flex items-center">
                    <img src={TRU} onClick={() => handleChangeNumber("-")} />
                  </div>
                  <div className="px-2  flex items-center">{numberTop}</div>
                  <div className="px-2 flex items-center">
                    <img src={CONG} onClick={() => handleChangeNumber("+")} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="bg-white w-full rounded-2xl">
                <div className="grid grid-cols-10 text-black pt-3">
                  <div className="col-span-3 w-20">
                    <img src={select_gift?.select_gift_list[1].gift_image} />
                  </div>
                  <div className="col-span-7">
                    <div className="text-[16px]">
                      {select_gift?.select_gift_list[1].gift}
                    </div>
                    <div>
                      còn lại:{" "}
                      <span className="text-[#014FA2]">
                        7{select_gift?.select_gift_list[1].remaining}50
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-black flex justify-end pb-1 pr-5">
                  <div className=" shadow-btnnumber pb-2 flex">
                    <div className="px-2 flex items-center">
                      <img
                        src={TRU}
                        onClick={() => handleChangeNumberDown("-")}
                      />
                    </div>
                    <div className="px-2  flex items-center">{numberDown}</div>
                    <div className="px-2 flex items-center">
                      <img
                        src={CONG}
                        onClick={() => handleChangeNumberDown("+")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-[50px] box-border text-[#333] font-light">
            <button
              onClick={() => {
                if (winningGift?.spinRemain >= 1) {
                  handleChangeAnnounceStatus();
                  handleEnableBtn();
                } else {
                  if (numberCurrent === numberDown + numberTop) {
                    handleNavigateSucess();
                  }
                }
              }}
              style={style}
              className={classNames(
                " text-[#fff] px-[60px] py-[15px] rounded-3xl text-[16px] font-bold",
                {
                  "color-button-enable":
                    numberCurrent === numberDown + numberTop,
                  "bg-[#bdbdbd]": numberCurrent !== numberDown + numberTop,
                }
              )}
            >
              Đồng ý
            </button>
          </div>
        </div>
        <IconPhoneAndZalo />
        <FooterLogo />
      </div>
    </>
  );
}

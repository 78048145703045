export default function TabReward({ item }) {
  console.log(item);
  return (
    <>
      <tr className="h-12 grid grid-cols-9 ">
        <td className="font-regular col-span-2 flex items-center">
          {item?.phone}
        </td>

        <td className=" col-span-5 flex items-center">
          <div className="border-table-listwhitegift w-full">{item?.gift}</div>
        </td>
        <td className=" col-span-2 flex items-center">
          <div className="border-table-listwhitegift ">
            {item?.validate_time}
          </div>
        </td>
      </tr>
    </>
  );
}

import "../../assets/css/Home.css";
import "../../assets/css/background__Footer.css";
import POPUP from "../../assets/fontawesome/image/popup.png";
import INFO from "../../assets/fontawesome/image/i.svg";
import VONG__QUAY from "../../assets/fontawesome/image/vong_quay.svg";
import GIFT from "../../assets/fontawesome/image/gift_home.png";
import { useEffect, useState } from "react";
import CLOSE from "../../assets/fontawesome/image/close.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/css/font-text.css";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import { homeServices } from "../../services/apiService/homeServices";
import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import { useSelector } from "react-redux";
import NOTI_RUN from "../../assets/fontawesome/image/noti-run.png";
import Marquee from "react-fast-marquee";
import ICON_NOTIFY from "../../assets/fontawesome/image/icon_notify.svg";

import CarouselTopItem from "./CarouselTopItem";
import CarouselMiddleItem from "./CarouselMiddleItem";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { useQuery } from "react-query";
import { campaignServices } from "../../services/apiService/campaignServices";
// import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
let titlePopup = "Vui lòng đăng nhập để thấy được chức năng này";
let redirectPathOk = "/login";
let redirectPathLater = "/";

export default function Home() {
  document.body.style.backgroundColor = "white";
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });

  const nameData = localStorage.getItem("nameData");
  const phoneData = localStorage.getItem("phoneData");
  const [popUp, setPopup] = useState(false);
  const navigation = useNavigate();

  const [numberCampaign, setNumberCampaign] = useState(24);

  const fetchData = async (filter) => {
    console.log(filter);
    const res = await homeServices.getRunningCampaign();
    const filterlist = res?.campaign_list?.filter((x) => x.id !== filter);
    console.log(filterlist);
    return filterlist;
  };
  const dataCampaignList = useQuery(["campaignctkm", numberCampaign], () =>
    fetchData(numberCampaign)
  );
  console.log(dataCampaignList);

  const { data } = useQuery({
    queryKey: ["video"],
    queryFn: () => homeServices.getCampaignClip(),
  });
  console.log(data);
  const dataCampaignTopDown = useQuery({
    queryKey: ["campaigntopdown"],
    queryFn: () => homeServices.getRunningCampaignTopAndDown(),
  });
  console.log(dataCampaignTopDown);
  const dataCampaign = useQuery({
    queryKey: ["getAllCampaign"],
    queryFn: () => homeServices.getRunningCampaignAll(),
  });
  const dataGiftLS = useQuery({
    queryKey: ["getCampaignGift"],
    queryFn: () => campaignServices.getCampaignPrize(1, 10, 1),
  });
  const dataGiftTP = useQuery({
    queryKey: ["getCampaignGift"],
    queryFn: () => campaignServices.getCampaignPrize(2, 10, 1),
  });
  console.log(dataGiftTP);

  console.log(dataGiftLS);

  // campaignServices
  //     .getCampaignGift(id)
  //     .then((res) => {
  //       set_data_gift(res.list_gift);
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // const getCampaignClip = () => {
  //   homeServices
  //     .getCampaignClip()
  //     .then((res) => {
  //       const clip =
  //         res.homepage_clip_link.replace("watch?v=", "embed/") + "?autoplay=1";
  //       setCampaignClip(clip);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const getCampaignTopAndDown = () => {
  //   homeServices
  //     .getRunningCampaignTopAndDown()
  //     .then((res) => {
  //       localStorage.setItem("contact", res?.banner_top[0]?.hotline);
  //       setCampaignTop(res.banner_top);
  //       setCampaignDown(res.banner_middle);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getRunningCampaign();
    // getCampaignClip();
    // getCampaignTopAndDown();
    localStorage.removeItem("checkNoti");
  }, []);

  // const handleTakePhoto = () => {
  //   navigation("/guide-takeaphoto");
  // };
  // const handleNotify = () => {
  //   navigation("/list-notify");
  // };
  const handleGift = () => {
    // navigation("/list-gift");
    // if (token) {
    //   navigation("/list-gift");
    // } else {
    //   setPopup(true);
    // }
    navigation("/guide-takeaphoto");
  };

  const handleRotation = () => {
    navigation("/list-rotation");
  };
  const handleSeeAll = () => {
    navigation("/list-promotion");
  };
  const handleNotify = () => {
    navigation("/list-notify");
    localStorage.setItem("checkNoti", "home");
  };

  const handleSelectGift = (id) => {
    navigation(`/deal-details/${id}`);
  };

  const handleNowork = () => {};

  function reformatDate(dateStr) {
    var dArr = dateStr.split("-"); // ex input: "2010-01-18"
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0]; //ex output: "18/01/10"
  }
  const [open_popup, set_popup] = useState(true);
  const closePopupModal = () => {
    set_popup(false);
    // localStorage.setItem("add", "opened");
  };

  // useEffect(() => {
  //   const a = localStorage.getItem("add");
  //   console.log(a);
  //   if (a === "opened") {
  //     set_popup(false);
  //   } else {
  //     set_popup(true);
  //   }
  // });

  const onClickTakeAPhoto = () => {
    navigation("/guide-takeaphoto");
    localStorage.setItem("add", "opened");
  };
  return (
    <>
      <div className="mt-2.5">
        <div className="home__container absolute top-0 h-[183px] max-h-screen w-screen rounded-b-[80px] bg-[100%] bg-no-repeat max-[320px]:h-[170px] min-[650px]:h-[230px]">
          <div className="flex flex-nowrap justify-between p-[30px_25px_0_25px]">
            <div className="flex w-full items-center justify-between not-italic text-white">
              <div className="flex">
                <div className=" home__container-head-logoname h-[80px]  bg-no-repeat p-[0_60px_65px_0]">
                  {" "}
                </div>
                <div className="ml-2 flex items-center text-left not-italic max-[320px]:w-[150px]">
                  <div>
                    <div className="font-semibold">Hi</div>
                    <div>
                      {token ? (
                        nameData ? (
                          phoneData
                        ) : (
                          <>Chưa đăng nhập</>
                        )
                      ) : (
                        <>Chưa đăng nhập</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div onClick={handleNotify}>
                  <img src={ICON_NOTIFY} />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-2/4 w-full translate-y-1/2 -translate-x-1/2 px-6 py-0">
            <div className=" image group  relative m-auto  w-full min-w-full">
              <Carousel
                className="campaign-top-height shadow-campaign-top max-h-[265px] rounded-xl"
                autoPlay
                centerMode={false}
                showArrows={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                preventMovementUntilSwipeScrollTolerance={false}
              >
                {dataCampaignTopDown?.data?.banner_top?.map((item) => (
                  <CarouselTopItem item={item} key={item} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>

        <div className="menu-home z-10 mt-[285px] grid grid-cols-2 gap-4 p-[0_25px]">
          <div className="background-menu-cam pl-2 pr-3" onClick={handleGift}>
            <div className="rounded-[100px] bg-[#ffffff] p-[11px_8px_11px_8px]">
              <img src={GIFT} className="w-6" />
            </div>
            <div className="menu-bar text-[12px] text-[#ffffff]">
              CHỤP ẢNH HÓA ĐƠN
            </div>
          </div>
          <div className="background-menu pl-0 pr-2" onClick={handleRotation}>
            <img src={VONG__QUAY} className="w-10 p-[0_0px_0_5px]" />

            <div className="menu-bar text-[12px] text-[#333333]">
              VÒNG XOAY MAY MẮN
            </div>
          </div>
        </div>

        {/* <div className="my-5 flex w-full justify-around px-5">
          {dataCampaignTopDown?.data?.banner_middle?.map((item) => (
            <div
              className="flex w-1/4 justify-center"
              // onClick={
              //   item.id === 3
              //     ? () => handleNowork(item.id)
              //     : () => handleSelectGift(item.id)
              // }
              key={item.id}
            >
              <img src={item.campaing_logo} className="w-10/12" />
            </div>
          ))}
        </div> */}
        <div className="border-noti-voucher mx-5 my-5 flex bg-[#F63440] font-bold text-white ">
          <Marquee>
            {dataGiftTP?.data?.list_prizes?.map((res, index) => {
              return (
                <div className="flex" key={index}>
                  <div>
                    <img src={NOTI_RUN} />
                  </div>
                  &nbsp;
                  {res?.gift} - {res?.phone} - {res?.validate_time}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              );
            })}
            {dataGiftLS?.data?.list_prizes?.map((res, index) => {
              return (
                <div className="flex" key={index}>
                  <div>
                    <img src={NOTI_RUN} />
                  </div>
                  &nbsp;
                  {res?.gift} - {res?.phone} - {res?.validate_time}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              );
            })}
          </Marquee>
        </div>
        <div className="mx-[25px] mt-[21px]">
          <Carousel
            className="m-auto w-full max-w-[100vw]"
            autoPlay
            centerMode={false}
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            preventMovementUntilSwipeScrollTolerance={false}
          >
            {dataCampaignTopDown?.data?.banner_bottom?.map((item) => (
              <CarouselMiddleItem item={item} key={item} />
            ))}
          </Carousel>
        </div>

        {/* <div className="rounded-2xl mt-[21px] max-w-[100vw] h-[213px] text-xs m-auto px-6 relative group">
          <iframe
            width="100%"
            height="213px"
            style={{ fontSize: "3px", borderRadius: "16px" }}
            src={
              data?.homepage_clip_link.replace("watch?v=", "embed/") +
              "?autoplay=1"
            }
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div> */}

        <div className="mt-5 flex items-center justify-between px-[25px]">
          <div className="text-2xl font-bold text-[#333] ">Khuyến mãi</div>
          <div
            className="text-sm font-semibold text-[#f63440]"
            onClick={handleSeeAll}
          >
            Xem tất cả
          </div>
        </div>

        <Carousel
          axis="horizontal"
          centerMode={true}
          showArrows={false}
          infiniteLoop={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          className="mt-3 mb-3 min-w-full"
          preventMovementUntilSwipeScrollTolerance={false}
        >
          {dataCampaignList?.data?.map((item) => (
            <NavLink to={`/deal-details/${item.id}`} key={item}>
              <div className="pl-[25px]">
                <img src={item.campaign_banner} className="rounded-xl" />
                <div className="mt-[8px] w-full text-left text-[13px] font-semibold leading-[18px] text-[#4f4f4f]">
                  {item.name}
                </div>
                <div className="container__list-product-time mt-[4px] text-[13px] leading-4 text-[#0068c0] ">
                  Từ {reformatDate(item.start_date)} đến{" "}
                  {reformatDate(item.end_date)}
                </div>
              </div>
            </NavLink>
          ))}
        </Carousel>
        <div className="h-20"></div>
      </div>
      <IconPhoneAndZalo />
      <NavbarHome />
      {popUp ? (
        <ConfirmPopupLogin
          titlePopup={titlePopup}
          onClosePopup={() => {
            setPopup(false);
          }}
          handleCancel={() => {
            navigation("/");
          }}
          handleOk={() => {
            navigation("/login");
          }}
        />
      ) : null}
      {/* {open_popup ? (
        <div
          className="show fixed top-0 left-0 z-40 w-full overflow-y-auto"
          id="modal"
        >
          <div className="min-height-100vh flex items-center justify-center px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-60" />
              <div className="popup-box">
                <div className="relative min-h-screen">
                  <div className="absolute top-1/2 -translate-y-1/2">
                    <img
                      src={CLOSE}
                      className="absolute -top-4 right-7 w-9"
                      onClick={closePopupModal}
                    />
                    <img
                      src={POPUP}
                      className=" z-10 w-full px-10 "
                      onClick={onClickTakeAPhoto}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </>
  );
}

import BTN_NEXT from "../../assets/fontawesome/image/btn-next.svg";
import "../../assets/css/guideTakeAPhoto.css";
export default function CarouselTakeAPhoto({ item }) {
  return (
    <>
      <div>
        <img
          src={item.url}
          className=" w-full rounded-2xl bg-center bg-cover duration-500"
        />
        <div className="flex w-full mt-0 justify-start items-start rounded-lg">
          <div className="title-description-c flex justify-start text-left mt-10 font-semibold">
            <div
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
              className="font-regular ml-3"
            ></div>
          </div>
        </div>
        <div className="mt-10 ml-8"></div>
      </div>
    </>
  );
}

export default function TabGift({ item }) {
  return (
    <>
      <tr className="h-12">
        <td className="font-regular">{item.gift}</td>
        <td className="">
          <div className="border-table-listgift mt-1 ml-0"> {item.plan}</div>
        </td>
        <td className="">
          <div className="border-table-listwhitegift ">{item.used}</div>
        </td>
        <td className="">
          <div className="border-table-listwhitegift ">{item.remaining}</div>
        </td>
      </tr>
    </>
  );
}

export default function ParticipateHisItem({ lstProduct }) {
  console.log(lstProduct);
  function reformatDate(dateStr) {
    var dArr = dateStr.split("-"); // ex input: "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0].substring(2); //ex output: "18/01/10"
  }
  return (
    <>
      <img
        src={lstProduct.campaign_avatar}
        className="containerNotify__background-itemImage w-[48px] h-[48px] rounded-lg"
      />
      <div className="containerNotify__background-itemChild pl-2.5 box-border">
        <div className="containerNotify__background-itemChildName content-notify  ">
          {lstProduct.title}
        </div>
        <div className="content-description-his content-notify corlor-text-darkblue leading-[18px]">
          {lstProduct.campaign_name}
        </div>
        <div className="containerNotify__background-itemChildTime">
          {lstProduct.date}
        </div>
      </div>
      <div className="text-right flex flex-col justify-between ml-auto">
        <div className="period-parhis corlor-text-primaryblue">
          {reformatDate(lstProduct.campaign_start_date)} {" - "}
          {reformatDate(lstProduct.campaign_end_date)}{" "}
        </div>
        <div className="quantity-parhis corlor-text-primarygreen text-[10px] w-[110px]">
          {lstProduct.count_voucher !== 0 ? (
            <>
              {" "}
              X{lstProduct.count_voucher} VQMM
              <br />
            </>
          ) : (
            ""
          )}
          {lstProduct.count_gift !== 0 ? `X${lstProduct.count_gift} quà` : ""}
        </div>
      </div>
    </>
  );
}

import "../../assets/css/backgroundListNotify.css";
import product from "../../assets/fontawesome/image/image_product.svg";
import "../../assets/css/backgroundPhone.css";
import "../../assets/css/listNotify.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import { useEffect, useState } from "react";
import { homeServices } from "../../services/apiService/homeServices";
import { NavLink } from "react-router-dom";
import LOGO_PG from "../../assets/fontawesome/image/logo_png.png";
const TITLE = "Thông Báo";
function ListNotify() {
  const listProduct = [
    {
      name: "Thẻ cào điện thoại",
      image: product,
      description: "Từ CT Tết thơm mềm Đẹp đúng nghĩa",
      time: "15 May 2020 8:30 am",
      status: false,
    },
    {
      name: "[CoopMart] Nhận được x1 VQMM",
      image: product,
      description: "Đổi thưởng chương trình",
      time: "20 Mar 2020 8:30 am",
      status: true,
    },
  ];

  const [listNotify, setListNotify] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    homeServices
      .getRunningCampaign()
      .then((res) => {
        console.log(res);
        setListNotify(res.campaign_list);
      })
      .catch((err) => {});
  }, []);

  const handleDetailNotify = (id) => {
    console.log(id);
  };
  function reformatDate(dateStr) {
    var dArr = dateStr.split("-"); // ex input: "2010-01-18"
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0]; //ex output: "18/01/10"
  }
  return (
    <>
      <div className="contain">
        <HeaderBackground TITLE={TITLE} buttonBack={"/infor-customer"} />
        <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[86%] w-full z-10">
          <ul className="containerNotify__background-list pt-7 box-border  z-50">
            {listNotify.map((lstProduct) => (
              // lstProduct.status === true ? (
              <NavLink
                to={`/deal-details/${lstProduct.id}`}
                key={lstProduct.id}
              >
                <li className="containerNotify__background-item">
                  <img
                    src={
                      lstProduct.campaign_avatar
                        ? lstProduct.campaign_avatar
                        : LOGO_PG
                    }
                    alt="image"
                    className="containerNotify__background-itemImage max-w-[48px] rounded-lg"
                  />
                  <div className="containerNotify__background-itemChild pl-2.5 box-border">
                    <div className="containerNotify__background-itemChildName content-notify  ">
                      {lstProduct.name}
                    </div>
                    <div className="containerNotify__background-itemChildContent content-notify  leading-[18px]">
                      {lstProduct.content}
                    </div>
                    <div className="containerNotify__background-itemChildTime">
                      Từ {reformatDate(lstProduct.start_date)} đến{" "}
                      {reformatDate(lstProduct.end_date)}
                    </div>
                  </div>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>

        {/* </div> */}
        <IconPhoneAndZalo />
      </div>
    </>
  );
}
export default ListNotify;

import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import "../../assets/css/backgroundListNotify.css";
import "../../assets/css/prizeRules.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import Footer from "../../component/Footer/Footer";
import { useEffect, useState } from "react";
import { campaignServices } from "../../services/apiService/campaignServices";
import Logo from "../../assets/fontawesome/image/logo_mvc.png";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
const TITLE = "Thể lệ giải thưởng";
export default function PrizeRules() {
  const [prizeTerm, setPrizeTerm] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    campaignServices
      .getPrizeTerm()
      .then((res) => {
        setPrizeTerm(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <HeaderBackground TITLE={TITLE} buttonBack={"/"} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] top-32 h-[86%] w-full z-10">
        <div className="containerNotify__background-list py-9 box-border z-20 mx-6 ">
          <div className=" box-border z-20 ">
            <div className="font-semibold leading-4 mt-3">
              <div className="uppercase corlor-text-darkblue">Thể lệ chung</div>
              <ul className="font-light text-[12px] leading-[19px] corlor-text-darkblack mt-2">
                <li>
                  - Mỗi khách hàng được tham gia tối đa 5 hóa đơn, mỗi hóa đơn
                  tối đa 3 phần quà.
                </li>
                <li>
                  - Hóa đơn hợp lệ để tham gia chương trình là hóa đơn mua hàng
                  được phát hành trong thời gian diễn ra chương trình.
                </li>
                <li>- Hóa đơn hợp lệ là hóa đơn gốc của siêu thị.</li>
                <li>
                  - Không áp dụng cho hóa đơn in lại và hóa đơn photo. Không áp
                  dụng cho hóa đơn cộng gộp.
                </li>
              </ul>
            </div>

            <div className="font-semibold leading-4 text-[13px] mt-3">
              <div className="uppercase">Các quy định khác</div>
              <ul className="font-light text-[12px] leading-[19px] corlor-text-darkblack mt-2">
                <li>
                  <span className="content-prizerules-ul">
                    - Đối với giải thưởng là E-Voucher UrBox:
                  </span>{" "}
                  <span>
                    Khách hàng sẽ nhận được link E-Voucher và mã bảo vệ trong
                    tin nhắn điện thoại. Khách hàng vui lòng kiểm tra tin nhắn
                    sau khi thực hiện hết các lượt quay, nhấn vào link trong tin
                    nhắn và nhập mã bảo vệ để sử dụng E-Voucher.
                  </span>
                </li>
                <li>
                  <span className="content-prizerules-ul">
                    - Đối với giải thưởng là E-Voucher Gotit:
                  </span>{" "}
                  <span>
                    Khách hàng sẽ nhận được link E-Voucher trong tin nhắn điện
                    thoại. Khách hàng vui lòng kiểm tra tin nhắn sau khi thực
                    hiện hết các lượt quay, nhấn vào link trong tin nhắn và nhập
                    số điện thoại để nhận mã OTP kích hoạt E-Voucher để sử dụng.
                  </span>
                </li>
                <li>
                  <span className="content-prizerules-ul">
                    - Đối với giải thưởng là thẻ nạp điện thoại:
                  </span>{" "}
                  <span>
                    Tiền sẽ được nạp trực tiếp vào tài khoản điện thoại của
                    Khách hàng, chậm nhất là 24h.
                  </span>
                </li>
                <li>
                  <span className="content-prizerules-ul">
                    - Đối với giải thưởng là quà hiện vật:
                  </span>{" "}
                  <span>
                    Hệ thống sẽ gửi tin nhắn tự động xác nhận giải thưởng. BTC
                    sẽ chủ động liên hệ khách hàng trúng thưởng để trao thưởng.
                  </span>
                </li>
                <li>- Giải thưởng không có giá trị quy đổi thành tiền mặt.</li>
                <li>
                  - Thời gian trả thưởng cho khách hàng chậm nhất là 45 ngày kể
                  từ ngày kết thúc chương trình.
                </li>
                <li>
                  - Khách hàng sẽ nhận thưởng ngay tại siêu thị mua hàng hoặc
                  quà tặng sẽ được gửi tới nhà khách hàng tùy theo quy định của
                  chương trình.
                </li>
                <li>
                  - Khi nhận thưởng, khách hàng xuất trình hóa đơn mua hàng và
                  tin nhắn SMS thông báo trúng giải được gửi đến SĐT tham gia
                  chương trình. Khách hàng có trách nhiệm cung cấp thông tin
                  chính xác khi tham gia chương trình đổi quà. Công ty P&G có
                  quyền từ chối xử lý trong các trường hợp sai sót do khách hàng
                  cung cấp thông tin không chính xác.
                </li>
                <li>
                  - Khách hàng trúng thưởng chịu mọi chi phí phát sinh liên quan
                  đến việc nhận thưởng (nếu có) như : đi lại, ăn ở,....
                </li>
                <li>
                  - Công ty P&G có quyền sử dụng thông tin và hình ảnh khách
                  hàng trúng thưởng cho mục đích trao thưởng, báo cáo, quản lý
                  tiếp thị cho các nhãn hàng P&G.
                </li>
                <li>
                  - Chương trình có thể kết thúc sớm hơn dự kiến do số lượng quà
                  tặng có hạn.
                </li>
                <li>- Mọi thắc mắc về chương trình khách hàng liên hệ qua</li>
                <li className="hotline-prizerules content-prizerules-ul">
                  <span>Hotline:</span>{" "}
                  <span className="corlor-text-darkred">(028) 36222399</span>
                </li>
                <li className="hotline-prizerules content-prizerules-ul">
                  <span>Email:</span> <span>contact@mvc.com.vn</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center py-[50px] box-border font-light">
            <input
              type="submit"
              className="btn-prizerules color-button-primary corlor-text-darkwhite px-[32px] py-[15px] text-center rounded-3xl cursor-pointer leading-5"
              value="Tham gia ngay"
            />
          </div>
          {/* <div className="mb-10" dangerouslySetInnerHTML={{ __html: prizeTerm?.prize_tc }}></div> */}
          <FooterLogo />
        </div>

        <IconPhoneAndZalo />
      </div>
    </div>
  );
}

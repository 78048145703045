import { axiosInstance, configHeader } from "./configURL";

export const historyService = {

    getListHistory: () => {
        return axiosInstance.post("/marico/get_history",
            configHeader
        );
    },
};

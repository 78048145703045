import React from "react";
import "./Success.css";
import check_img from "../../assets/fontawesome/image/check-img.png";
import home_icon from "../../assets/fontawesome/image/home_blue.png";
import gift_icon from "../../assets/fontawesome/image/Gift-success.png";
import TITLE_SUCCESS from "../../assets/fontawesome/image/title-success-x.png";
import { Link } from "react-router-dom";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
export default function Success() {
  return (
    <div>
      <div className="spin-the-wheelsuccess contain text-[#FFFFFF]">
        <div className="temp">
          <div className="uppercase text-[#96C61C] pt-[82px] flex justify-center text-center text-[28px] font-bold">
            <img src={TITLE_SUCCESS} />
          </div>
          <div className="check-img">
            <img src={check_img} alt="" />
          </div>
          <div className="flex justify-center gap-1 mt-[250px] btn-img-success">
            <Link to="/" className="bg-[#96C61C] success-btn">
              <img src={home_icon} alt="" />
              <span>Về trang chủ</span>
            </Link>
            <Link to="/list-gift" className="bg-[#0A63C1] success-btn">
              <img src={gift_icon} alt="" />
              <span>Quà của tôi</span>
            </Link>
          </div>
        </div>
      </div>
      <IconPhoneAndZalo />
      <FooterLogo />
    </div>
  );
}

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/LoginPage/Login";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home/Home";
import Register from "./pages/RegisterPage/Register";
import TakeAPhoto from "./pages/TakeAPhoto/TakeAPhoto";
import HotdealDetails from "./pages/HotdealDetails/HotdealDetails";
import ListRotation from "./pages/ListRotation/ListRotation";
import GuidePage from "./pages/GuidePage/GuidePage";
import ListGift from "./pages/ListGift/ListGift";
import PrizeRules from "./pages/PrizeRules/PrizeRules";
import GuideTakeAPhoto from "./pages/GuideTakeAPhoto/GuideTakeAPhoto";
import ParticipateHistory from "./pages/ParticipateHistory/ParticipateHistory";
import ListPromotion from "./pages/ListPromotion/ListPromotion";
import InforCustomer from "./pages/InforCustomer/InforCustomer";
import SpinTheWheel from "./pages/SpinTheWheel/SpinTheWheel";
import Success from "./pages/Success/Success";
import AnnouncePrize from "./pages/AnnouncePrize/AnnouncePrize";
import ConfirmOTP from "./pages/ConfirmOTP/ConfirmOTP";
import UpdateCustomerInfo from "./pages/UpdateCustomerInfo/UpdateCustomerInfo";
import ProtectedRoute from "./HOC/ProtectedLayout/ProtectedRoute";
import LoginNoRegister from "./pages/LoginPageNoRegister/LoginNoRegister";
import { userDataLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import {
  isLocalhost,
  isMobileDevice,
  isSupportedBrowser,
  isWebKitBrowser,
} from "./services/deviceModel";
import NoAccessBrowser from "./pages/NoAccessBrowser/NoAccessBrowser";
import ListNotify from "./pages/ListNotify/ListNotify";
import SelectGift from "./pages/SelectGift/SelectGift";
import { QueryClient, QueryClientProvider } from "react-query";
import ListGiftForCustomer from "./pages/ListGiftForCustomer/ListGiftForCustomer";
import LoginQR from "./pages/LoginQR/LoginQR";
import InputCodeQR from "./pages/LoginQR/InputCodeQR";
import SuccessFinish from "./pages/Success/SuccessFinish";
import LoginQRAA from "./pages/LoginQR/LoginQRAA";
import InputCodeQRAA from "./pages/LoginQR/InputcodeQRAA";
import CountDownOrder from "./pages/GuideTakeAPhoto/CountDownOrder";
import { useEffect } from "react";
import { homeServices } from "./services/apiService/homeServices";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools ";

function App() {
  let { token } = userDataLocal.get();
  setAuthorization(token);
  // let access = isSupportDevice();
  let access = false;
  let mobileDevice = isMobileDevice();
  let webKitBrowser = isSupportedBrowser();
  if ((mobileDevice && webKitBrowser) || isLocalhost()) {
    access = true;
  }
  const queryClient = new QueryClient();
  useEffect(() => {
    homeServices
      .getRunningCampaignTopAndDown()
      .then((res) => {
        console.log(res);
        localStorage.setItem("HOTLINE", res?.banner_top[0]?.hotline);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {access ? (
        <div className="App">
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/rotate" element={<CountDownOrder />} /> */}

                <Route path="/login" element={<Login />} />
                <Route path="/doiqua" element={<LoginQR />} />
                {/* <Route path="/doiquaaa" element={<LoginQRAA />} /> */}
                <Route
                  path="/login-no-register"
                  element={<LoginNoRegister />}
                />
                <Route path="/selectgift" element={<SelectGift />} />

                <Route path="/takephoto" element={<TakeAPhoto />} />
                <Route path="/confirm-otp" element={<ConfirmOTP />} />

                <Route path="/register" element={<Register />} />

                <Route path="/list-notify" element={<ListNotify />} />
                <Route
                  path="/list-gift-custom/:id"
                  element={<ListGiftForCustomer />}
                />
                <Route path="/deal-details/:id" element={<HotdealDetails />} />

                <Route path="/guide" element={<GuidePage />} />
                <Route path="/prize-rules" element={<PrizeRules />} />
                <Route
                  path="/guide-takeaphoto/:campaignId?"
                  element={<GuideTakeAPhoto />}
                />
                <Route path="/finish" element={<SuccessFinish />} />
                <Route
                  path="/participate-history/:tick?"
                  element={<ParticipateHistory />}
                />
                <Route path="/list-promotion" element={<ListPromotion />} />
                <Route path="/doiqua-inputcode" element={<InputCodeQR />} />
                <Route path="/infor-customer" element={<InforCustomer />} />
                {/* <Route path="/doiqua-inputcodeaa" element={<InputCodeQRAA />} /> */}

                <Route path="/wheel-success" element={<Success />} />
                <Route path="/announce-prize" element={<AnnouncePrize />} />
                <Route path="/list-rotation" element={<ListRotation />} />

                <Route element={<ProtectedRoute />}>
                  <Route path="/list-gift/:tick?" element={<ListGift />} />

                  <Route
                    path="/update-customer-info/:tick?"
                    element={<UpdateCustomerInfo />}
                  />
                  <Route path="/wheel/:id" element={<SpinTheWheel />} />
                </Route>
              </Routes>
            </QueryClientProvider>
          </BrowserRouter>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NoAccessBrowser />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;

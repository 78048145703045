import { useSearchParams } from "react-router-dom";

export const useQueryString = () => {
  const [searchParams] = useSearchParams();

  const searchParamObject = Object.fromEntries([...searchParams]);
  console.log(searchParamObject);
  return searchParamObject;
};
const date = new Date();

export const formattedDate = date.toLocaleDateString("en-GB", {
  timeZone: "Asia/Ho_Chi_Minh",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

export const compareDate = (dateExpired) => {
  const datearray = dateExpired.split("/");
  const newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
  const dateExpire = new Date(newdate);
  // const dateCurrent = new Date(formattedDate);
  console.log(date > dateExpire);
  console.log(date);
  console.log(dateExpire);
  return date >= dateExpire;
};

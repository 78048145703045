import { axiosInstance, config, configHeader } from "./configURL";

export const campaignServices = {
  getPrizeTerm: () => {
    return axiosInstance.post(
      "/marico/get_prize_term",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCampaign: () => {
    return axiosInstance.post("/marico/get_all_campaign", {}, configHeader);
  },
  getCampaignDetailApi: (campaignId) => {
    return axiosInstance.post(
      "/marico/get_campaign_details",
      {
        params: { campaign_id: campaignId },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCampaignGift: (campaignId) => {
    return axiosInstance.post(
      "/marico/list_gift_by_campaign",
      {
        params: { campaign_id: campaignId },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCampaignPrize: (campaignId, limit, page) => {
    return axiosInstance.post(
      "/marico/list_win_by_campaign",
      {
        params: { campaign_id: campaignId, limit: limit, page: page },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};

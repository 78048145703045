import React, { useEffect, useState } from "react";
import ModalGift from "../../pages/GuideTakeAPhoto/ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { format } from "date-fns";
import { receiptServices } from "../../services/apiService/receiptServices";
import CountDownOrder from "../../pages/GuideTakeAPhoto/CountDownOrder";

export default function SubmitReceipt({ trigger }) {
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [listPrize, setListPrize] = useState([]);
  const [so_ids, setSo_ids] = useState([]);
  const [status_lucky, setStatus_lucky] = useState(false);
  const [isShowModalErr, setIsShowModalErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    console.log(trigger);
    if (trigger) {
      submitReceipt(gcsResult);
    }
  }, [trigger]);

  const submitReceipt = (gcsResult) => {
    console.log(gcsResult);
    setIsUpload(true);
    let formData = new FormData();
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    formData.append("phone", localStorage.getItem("phoneData"));
    formData.append("customer_name", localStorage.getItem("nameData"));
    if (gcsResult.campaign_id) {
      formData.append("campaign_id", gcsResult.campaign_id);
    }
    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss"),
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    );
    receiptServices
      .submitReceiptApi(formData)
      .then((res) => {
        console.log(res)
        setStatus_lucky(res.lucky_draw)
        setListPrize(res.prize_list);
        setSo_ids(res.so_ids);
        setModalIsOpen(true);
      })
      .catch((err) => {
        console.log(err)

        setErrMsg(err);
        setIsShowModalErr(true);
      })
      .finally(() => {
        setIsUpload(false);
        localStorage.removeItem("GCS_RESULT");
      });
  };
  const handlePopupErrorOk = () => {
    console.log("popup press ok");
    navigation("/");
  };
  return (
    <>
      {isUpload ? (
        <div className="full-height absolute z-[200] flex w-full flex-col items-center justify-center bg-[#014FA2] backdrop-blur-sm">
          <div className="">
            <button className="load h-[100px] w-[100px]"></button>
          </div>
          <div className="flex justify-center px-[30px] text-center ">
            <p className="rounded-xl bg-slate-100 p-[10px] font-light text-black">
              Hóa đơn của quý khách đang được hệ thống ghi nhận. Vui lòng không
              rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    <ModalGift modalIsOpen={modalIsOpen} soIds={so_ids} listPrize={listPrize} status_lucky={status_lucky}></ModalGift>
      {isShowModalErr ? (
        <CountDownOrder
          errMsg={errMsg}
          handlePopupErrorOk={handlePopupErrorOk}
        />
      ) : null}
    </>
  );
}

import "../../assets/css/backgroundListNotify.css";
import "../../assets/css/backgroundPhone.css";
import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import "../../assets/css/listRotation.css";
import "../../assets/css/font-text.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { useEffect, useState } from "react";
import { luckyDrawService } from "../../services/apiService/LuckyDraw";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import { userServices } from "../../services/apiService/userServices";
import { compareDate, formattedDate } from "../ultil/utils";
import ConfirmPopupPhone from "../../component/ConfirmPopupPhone/ConfirmPopupPhone";
import ComponentNotify from "../../component/ConfirmPopupBtnHorizontal/ComponentNotify";

const TITLE = "Danh sách vòng quay";
const title =
  "Chương trình đã hết thời gian tham gia. Quý khách vui lòng quay lại tham gia các chương trình sau. Chân thành cảm ơn quý khách!";
export default function ListRotation() {
  const [luckyDrawList, setLuckyDrawList] = useState([]);
  const [current, setCurrent] = useState("1");

  const [isExpired, setIsExpired] = useState(false);
  const [statusGifts, setStatusGifts] = useState("done");

  const hanldeClick = (e, status_gifts) => {
    setCurrent(e.target.id);

    luckyDrawService
      .getLuckyDrawList(status_gifts)
      .then((res) => {
        console.log(res);
        setLuckyDrawList(res.lucky_draw_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log(formattedDate);
    window.scrollTo(0, 0);
    luckyDrawService
      .getLuckyDrawList("ready")
      .then((res) => {
        console.log(res);
        setLuckyDrawList(res.lucky_draw_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleToarstErr = () => {
    toast.error("Không có vòng quay");
  };
  console.log(luckyDrawList.expired_date >= formattedDate);
  const handleOpenExpired = () => {
    setIsExpired(!isExpired);
  };
  return (
    <div className="contain">
      <HeaderBackground TITLE={TITLE} buttonBack={"/"} />
      {isExpired ? (
        <ComponentNotify
          isShow={isExpired}
          handleOK={handleOpenExpired}
          message={title}
        />
      ) : (
        <div className="containerNotify__background absolute bottom-0 z-10 h-[86%] max-h-full w-full rounded-[30px_30px_0_0] bg-[#fff]">
          <div className="tab flex h-16 items-center justify-between align-middle">
            <div className="flex flex-auto justify-center">
              <button
                key="1"
                id={1}
                disabled={current === `${1}`}
                onClick={(e) => hanldeClick(e, "ready")}
                className="mt-[6px] text-[13px] font-semibold"
              >
                Sẵn sàng quay
              </button>
            </div>
            <div className="flex flex-auto justify-center">
              <button
                key="2"
                id={2}
                disabled={current === `${2}`}
                onClick={(e) => hanldeClick(e, "done")}
                className="mt-[6px] text-[13px] font-semibold"
              >
                Đã quay xong
              </button>
            </div>
            <div className="flex flex-auto justify-center">
              <button
                key="3"
                id={3}
                disabled={current === `${3}`}
                onClick={(e) => hanldeClick(e, "expired")}
                className="mt-[6px] text-[13px] font-semibold"
              >
                Hết hạn
              </button>
            </div>
          </div>
          <ul className="containerNotify__background-list z-20 box-border pt-8">
            {luckyDrawList?.map((item) => {
              return (
                <li
                  key={item.so_id}
                  className={`mx-[25px] mb-4 flex h-[126px] items-center overflow-hidden rounded-2xl bg-[#F0F0F0] ${
                    item.remaining_draw > 0
                      ? compareDate(item.expired_date)
                        ? "grayscale filter"
                        : ""
                      : "grayscale filter"
                  }`}
                  onClick={
                    compareDate(item.expired_date) ? handleOpenExpired : ""
                  }
                >
                  <div className="flex w-full">
                    <div className="flex h-full w-full">
                      <div className=" grid h-full w-full grid-cols-9 px-[2px]">
                        <div className="col-span-3">
                          {item.remaining_draw > 0 ? (
                            <NavLink
                              to={
                                compareDate(item.expired_date)
                                  ? ""
                                  : `/wheel/${item.so_id}`
                              }
                            >
                              <img
                                src={item.campaign_avatar}
                                className="image-product ml-3 mt-2 h-[90px] w-[90px] text-center"
                                alt=""
                              />
                            </NavLink>
                          ) : (
                            <img
                              src={item.campaign_avatar}
                              className="image-product ml-3 mt-2 h-[90px] w-[90px] text-center"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="gird relative col-span-6 -ml-[10px] grid-cols-1">
                          <div className="text-reponsive col-span-1 w-full text-[13px] font-semibold leading-[18px]">
                            {item.title}
                            <br />
                          </div>
                          <div className="col-span-1">
                            {item.remaining_draw > 0 ? (
                              <NavLink
                                to={
                                  compareDate(item.expired_date)
                                    ? ""
                                    : `/wheel/${item.so_id}`
                                }
                                className="text-reponsive font-regular leading-[20px]  text-[#828282] "
                              >
                                Bạn có{" "}
                                <span className="text-red-600">
                                  {item.remaining_draw}
                                </span>{" "}
                                vòng quay
                              </NavLink>
                            ) : (
                              <div
                                onClick={() => {
                                  handleToarstErr();
                                }}
                                className=" text-reponsive font-regular leading-[20px]  text-[#828282] "
                              >
                                Bạn có{" "}
                                <span className="text-red-600">
                                  {item.remaining_draw}
                                </span>{" "}
                                vòng quay
                              </div>
                            )}
                          </div>

                          <div className="text-reponsive-date absolute bottom-3 text-[#828282]">
                            {current === "2"
                              ? `Ngày quay xong${" "}`
                              : current === "1"
                                ? `Ngày tham gia${" "}`
                                : `Hết hạn ngày${" "}`}
                            <span className="text-[#1D86FA]">
                              {current === "2"
                                ? item?.done_date
                                : current === "1"
                                  ? item?.join_date
                                  : item?.expired_date}
                            </span>
                          </div>
                          <div className=" flex h-full flex-col items-end justify-start rounded-br-3xl">
                            {item.remaining_draw > 0 ? (
                              <NavLink
                                to={
                                  compareDate(item.expired_date)
                                    ? ""
                                    : `/wheel/${item.so_id}`
                                }
                                className=" bgr-icon-back background-bottom-icon relative -bottom-3 z-30 flex h-14 w-14 rounded-br-3xl"
                              >
                                <div className="icon-bottom z-40 mt-5 ml-5 h-6 w-6"></div>
                              </NavLink>
                            ) : (
                              <div
                                onClick={() => {
                                  handleToarstErr();
                                }}
                                className=" bgr-icon-back background-bottom-icon z-30 flex h-14 w-14"
                              >
                                <div className="icon-bottom z-40 mt-5 ml-5 h-6 w-6"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}

            <div className="h-20"></div>
          </ul>
        </div>
      )}

      <IconPhoneAndZalo />
      <FooterLogo />
    </div>
  );
}

import "../../assets/css/Login.css";
import React, { useState } from "react";
import "../../assets/fontawesome/css/all.min.css";
import "../../assets/css/background.css";
import "../../assets/css/backgroundHeader.css";
import "../../assets/css/backgroundButton.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import "../../assets/css/font-text.css";
import { userServices } from "../../services/apiService/userServices";
import { userDataLocal } from "../../services/localService/localService";
import { setUserData } from "../../Redux/Action/userAction";
import { useDispatch } from "react-redux";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
// giftCode: eSymTeKxhi
const style = {
  // backgroundColor: '#47CCFF',
  color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};
const styleButton = {
  color: "#333",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
};
function Login() {
  const hotline = localStorage.getItem("HOTLINE");

  document.body.style.backgroundColor = "black";
  const navigation = useNavigate();
  let dispatch = useDispatch();
  const [checkAgree1, setCheckAgree1] = useState(false);
  const [checkAgree2, setCheckAgree2] = useState(false);
  const [znsZalo, setZnsZalo] = useState("sms");

  const [notify, setNotify] = useState("");
  const handleAgree = (e) => {
    setNotify("");
    if (e === "ag1") {
      setCheckAgree1(!checkAgree1);
    } else if (e === "ag2") {
      setCheckAgree2(!checkAgree2);
    }
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  const onSubmit = (data) => {
    const phoneFormat = {
      phone: data.phone,
      name: data.name,
      login_type: znsZalo,
    };
    console.log(phoneFormat);
    userServices
      .postUserLogin(phoneFormat)
      .then((res) => {
        console.log(res);
        // toast.success(res.otp);
        const dataName = {
          name: data.name,
        };
        // const finalResult = Object.assign(res, dataName);
        localStorage.setItem("phoneData", data.phone);
        localStorage.setItem("nameData", data.name);
        userDataLocal.set(res);
        dispatch(setUserData(res));
        // toast.success("Thành công");
        navigation("/confirm-otp");
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleBack = () => {
    navigation("/");
  };
  const [show, setShowMore] = useState(false);
  const showMore = () => {
    setShowMore(!show);
  };

  const handleNotify = () => {
    setNotify("Vui lòng chọn tôi đồng ý để tiếp tục");
  };
  return (
    <>
      <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <div className="icon-back mt-[7%] py-[36px] h-8 flex items-center opacity-100 max-w-full w-full z-50">
          <i
            className="fa-solid fa-chevron-left fa-solid-back"
            onClick={handleBack}
          ></i>
        </div>
        <header className="p-0 w-52 h-9 leading-9 text-2xl not-italic ">
          ĐĂNG NHẬP
        </header>
        <div className="container__login-item p-[16px_0_68px_0] text-[#fff] text-base font-light w-[87%]">
          Nhập số điện thoại của bạn để nhận phần thưởng
        </div>
        <div className="container__login-form">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__login">
              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0  bg-[#fff] relative z-10 border-[#98EBFF] border-[1px]">
                <input
                  className="form__phone  text-[15px] box-border flex-[6]  pl-4 h-full z-10 text-black font-['Montserrat-Regular'] rounded-r-xl rounded-l-xl"
                  placeholder="Nhập tên của bạn "
                  {...register("name", {
                    required: "Không được để trống",
                    pattern: {
                      value: /^[\D*]{1,}$/,
                      message: "Vui lòng chỉ nhập kí tự",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] mb-7 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>

              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0  bg-[#fff] relative z-10 border-[#98EBFF] border-[1px]">
                {/* <!-- <input className="form__name" type="text" placeholder="Nhập tên của bạn"/> --> */}
                <div className="icon z-20 flex justify-center items-center text-[13px] ">
                  <i className="fa-solid fa-mobile-screen-button fa-solid-phone"></i>
                  {/* <img src="https://product.hstatic.net/200000122283/product/c-e1-bb-9d-vi-e1-bb-87t-nam_2c0683597d2d419fac401f51ccbae779_grande.jpg" className="icon__flag" /> <p>+84</p> */}
                </div>
                <input
                  className="form__phone m-[0_0_0_5px] text-[15px] box-border flex-[6] w-52 pl-2.5 h-full z-30 text-black font-['Montserrat-Regular'] rounded-r-xl"
                  placeholder="Nhập số điện thoại "
                  type="tel"
                  {...register("phone", {
                    required: "Không được để trống",
                    pattern: {
                      value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                      message: "Vui lòng nhập số",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
                {notify ? notify : ""}
              </div>

              {show === false ? (
                <>
                  <div
                    className={`${"corlor-text-white"} text-[12px] mt-[5px] font-regular leading-5 whitespace-pre-line flex flex-wrap break-words`}
                  >
                    <div style={{ marginTop: "18px" }}></div>
                    <div className="checkbox-tc">
                      <div
                        className="checkbox-tc"
                        style={{ marginBottom: "8px" }}
                      >
                        <div className="mt-5 flex font-light">
                          <div>
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              defaultChecked={checkAgree1}
                              onClick={(e) => handleAgree("ag1")}
                              className="checkbox-confirm-register w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                          <label
                            htmlFor="check"
                            className="text-[#333333] mr-[10px] font-[Montserrat] not-italic font-normal text-[13px] leading-[20px]"
                          >
                            {" "}
                          </label>
                          <label
                            htmlFor="check"
                            className={`${"corlor-text-white"} mr-[10px] font-regular not-italic font-normal text-[13px] leading-[20px]`}
                          >
                            Tôi đồng ý
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "0px" }}>
                      Bằng việc cung cấp thông tin và chọn TIẾP TỤC, tôi đồng ý
                      cho Marico SEA và bên cung cấp dịch vụ được Marico SEA
                      Việt Nam ủy quyền và được quản lý các thông tin cá nhân do
                      tôi cung cấp vào mục đích thống kê, kiểm tra việc thực
                      hiện chương trình. Mọi thắc mắc về chương trình tôi sẽ
                      liên hệ số hotline
                      <a href={`tel:${hotline}`} className="text-[#98C52B]">
                        {" "}
                        {hotline}
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <span
                  className={`${"corlor-text-white"} text-[12px]  font-regular leading-5 whitespace-pre-line flex flex-wrap break-words mt-5`}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      // padding: "5px 0 0 10px",
                      color: `${"#47CCFF"}`,
                      // "#003DA5",
                    }}
                    onClick={() => showMore()}
                  >
                    {show ? "" : "(Xem thêm)"}
                  </span>
                </span>
              )}
              {checkAgree1 ? (
                <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
                  <input
                    type="submit"
                    className="color-button-enable font-bold border-0 text-[#130682] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] leading-5"
                    value={`${"Đăng nhập"}`}
                  />
                </div>
              ) : (
                <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
                  <input
                    type="button"
                    className="color-button-disable font-bold border-0 text-[#33333] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light]  leading-5"
                    value={`${"Đăng nhập"}`}
                    onClick={handleNotify}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
      <FooterLogo />
    </>
  );
}

export default Login;

import { axiosInstance } from "./configURL";

export const luckyDrawService = {
  getLuckyDrawList: (status) => {
    return axiosInstance.post("/marico/api_get_lucky_draw_list", {
      params: { status: status },
    });
  },
  postDrawLuckyGift: (soID) => {
    return axiosInstance.post("/marico/api_submit_fg_lucky_gift", {
      params: { so_id: soID },
    });
  },
  postCountDraw: (soID) => {
    return axiosInstance.post("/marico/count_draw", {
      params: {
        so_id: soID,
        count: 1,
      },
    });
  },
  postSelectGift: (select_gift) => {
    return axiosInstance.post("/marico/api_select_gift", {
      params: select_gift,
    });
  },
};

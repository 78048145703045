import moment from "moment";
import "../../assets/css/backgroundPhone.css";
import "../../assets/css/backgroundListNotify.css";
import "../../assets/css/backgroundButton.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import "../../assets/css/font-text.css";
import { useEffect, useState } from "react";
import { campaignServices } from "../../services/apiService/campaignServices";
import FooterLogo from "../../component/Footer/FooterHasNavbar";

export default function HotdealDetails() {
  const [campaignDetail, setCampaignDetail] = useState(null);
  const { id } = useParams();
  const navigation = useNavigate();

  const handleBack = () => {
    navigation("/");
  };
  const getCampaignDetail = (id) => {
    campaignServices
      .getCampaignDetailApi(+id)
      .then((res) => {
        console.log(res);
        setCampaignDetail(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCampaignDetail(id);
  }, []);
  const formatDate = (date = "") => {
    return moment(date).format("DD/MM/YYYY");
  };
  return (
    <div className="contain">
      <div
        className="containerNotify__header-btnBack fixed top-0 left-0 mt-8 ml-5 h-[57px] w-[56px] opacity-95 cursor-pointer"
        onClick={handleBack}
      ></div>
      <div className="bg-no-repeat h-full mx-[15px] mt-[15px] ">
        <img
          className="w-full h-full rounded-3xl"
          src={campaignDetail?.campaign_avatar}
          alt=""
        />
      </div>

      <div className="max-h-full px-[25px] mt-5 max-[410px]:mt-0">
        <div className="italic text-[#1D86FA] font-normal text-[13px] leading-4 font-italic">
          <span>{formatDate(campaignDetail?.start_date)}</span> -{" "}
          <span>{formatDate(campaignDetail?.end_date)}</span>
        </div>
        <div className="flex justify-between">
          <div className="text-black font-bold text-[20px] leading-5 w-[75%] mt-1">
            {campaignDetail?.name}
          </div>

          {/* <img src={SHARE_ICON} className="w-14 h-14 cursor-pointer" /> */}
        </div>
        <div className="mt-5 text-[13px] leading-[21px] font-regular">
          <div
            dangerouslySetInnerHTML={{
              __html: campaignDetail?.campaign_banner_detail,
            }}
          ></div>
        </div>
      </div>

      <div className="flex justify-center mb-5">
        <div className="flex justify-center mr-5">
          <Link
            className="text-center color-button-enable my-[25px] text-white py-1 px-8 rounded-2xl cursor-pointer leading-6"
            to={`/list-gift-custom/${campaignDetail?.id}`}
          >
            Danh sách
            <br /> quà tặng
          </Link>
        </div>
        <div className="flex justify-center ">
          <Link
            className="flex items-center color-button-primary my-[25px] text-white py-1 px-3.5 rounded-2xl cursor-pointer"
            to={`/guide-takeaphoto/${campaignDetail?.id}`}
          >
            Tham gia ngay
          </Link>
        </div>
      </div>
      <FooterLogo />

      <IconPhoneAndZalo className="fixed" />
    </div>
  );
}

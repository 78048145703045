import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import ICON_NOTIFY from "../../assets/fontawesome/image/icon_notify.svg";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import GHIM from "../../assets/fontawesome/image/ghim.svg";
import ICON_HISTORY_PARTICI from "../../assets/fontawesome/image/icon-history-participate.svg";
import ICON_MYGIFT from "../../assets/fontawesome/image/icon-mygift.svg";
import "../../assets/css/inforCustomer.css";
import ICON_RIGHT from "../../assets/fontawesome/image/icon-right.svg";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import LOG_OUT from "../../assets/logout.png";
import { userServices } from "../../services/apiService/userServices";
import { setAuthorization } from "../../services/apiService/configURL";
import { userDataLocal } from "../../services/localService/localService";
import { setUserData } from "../../Redux/Action/userAction";
import { useDispatch } from "react-redux";
import { campaignServices } from "../../services/apiService/campaignServices";
const TITLE = "Thông tin khách hàng";
const clickFilter = "/list-notify";
export default function InforCustomer() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const handleMyGift = () => {
    navigation("/list-gift");
  };
  const handleHistory = () => {
    navigation("/participate-history/tick");
  };
  const handleLogout = () => {
    userServices
      .postLogout()
      .then((res) => {
        navigation("/login");
        localStorage.clear();
        // setAuthorization(undefined);
        // setAuthorization(null);
        // userDataLocal.set("");
        // dispatch(setUserData(null));
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGhim = () => {
    navigation("/guide");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderBackground
        TITLE={TITLE}
        buttonBack={"/"}
        filter={ICON_NOTIFY}
        clickFilter={clickFilter}
      />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[86%] w-full z-10">
        <ul className="containerNotify__background-list mt-8 mx-6 box-border z-20 grid-rows-2 border-2 rounded-lg">
          <li className="content-info-li border-b-2" onClick={handleHistory}>
            <div className="icon-infor-li">
              <img src={ICON_HISTORY_PARTICI} />
            </div>
            <div>Lịch sử tham gia</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handleMyGift}>
            <div className="icon-infor-li">
              <img src={ICON_MYGIFT} />
            </div>
            <div>Quà của tôi</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handleGhim}>
            <div className="icon-infor-li">
              <img src={GHIM} />
            </div>
            <div>Ghim Website vào màn hình</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li" onClick={handleLogout}>
            <div className="icon-infor-li">
              <img src={LOG_OUT} className="w-5" />
            </div>
            <div className="-ml-1">Đăng xuất</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
        </ul>
      </div>

      <IconPhoneAndZalo />
      <NavbarHome />
    </div>
  );
}
